import React from "react";
import "../App.css";
import styles from "./Lobby.module.css";
import { PlayerInfo } from "../../Shared/lobby/LobbySharedTypes";
import { Ping } from "./Ping";
import { HorizontalSpacer } from "../Shared/Spacer";

type LobbyPlayerProps = {
	index: number;
	player: PlayerInfo;
};

export const LobbyPlayer: React.FC<LobbyPlayerProps> = ({ index, player }) => {
	return (
		<div className={`${styles.player} ${player.state.client.ready ? styles.ready : ""}`} style={{ minWidth: 360 }}>
			<div style={{ width: "33%", textAlign: "left" }}>{`Player ${index + 1}`}</div>
			<div style={{ width: "33%", textAlign: "center" }}>{player.state.client.ready ? "Ready!" : ""}</div>
			<div style={{ width: "33%", textAlign: "right" }}>
				<HorizontalSpacer gap={4} centerAcross={true} style={{ justifyContent: "right" }}>
					<label>{player.state.server.ping}</label>
					<Ping ping={player.state.server.ping} />
				</HorizontalSpacer>
			</div>
		</div>
	);
};

export const EmptyPlayer: React.FC = () => {
	return (
		<div className={styles.player_empty}>
			<label>+</label>
		</div>
	);
};
