import React from "react";
import "../App.css";
import { VerticalSpacer } from "../Shared/Spacer";
import { useSession } from "../../Hooks/useSession";

type MainMenuProps = {
	setSocket: React.Dispatch<React.SetStateAction<WebSocket | undefined>>;
};

export const MainMenu: React.FC<MainMenuProps> = ({ setSocket }) => {
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [isPublic, setIsPublic] = React.useState(true);

	const { error, attemptJoinSession, attemptCreateSession } = useSession({
		setSocket,
		sessionType: "battleship",
	});

	return (
		<div>
			<h2>Battleship</h2>
			<VerticalSpacer>
				<div style={{ display: "flex", gap: 8, justifyContent: "flex-start", alignContent: "center" }}>
					<button
						className="App-button"
						style={{ minWidth: 200 }}
						onClick={async () => await attemptCreateSession(isPublic)}
					>
						New Session
					</button>
					<div style={{ display: "flex", alignItems: "center" }}>
						<input
							className="checkbox-input"
							type="checkbox"
							checked={isPublic}
							onChange={(e) => setIsPublic(e.target.checked)}
						/>
						<label className="checkbox-label">Public</label>
					</div>
				</div>
				<div style={{ display: "flex", justifyContent: "flex-start" }}>
					<div style={{ display: "flex", alignItems: "center", gap: 8 }}>
						<button className="App-button" style={{ minWidth: 200 }} onClick={async () => await attemptJoinSession()}>
							Join Public
						</button>
						<label className="checkbox-label" style={{ color: "red" }}>
							{error}
						</label>
					</div>
				</div>
				<div style={{ display: "flex", justifyContent: "flex-start", gap: 8 }}>
					<button
						className="App-button"
						style={{ minWidth: 200 }}
						onClick={async () => await attemptJoinSession(inputRef.current?.value)}
					>
						Join Private
					</button>
					<input className="App-input" type="text" ref={inputRef} placeholder="Enter Session ID" />
				</div>
			</VerticalSpacer>
		</div>
	);
};
