import React from "react";
import "../App.css";
import { PersistentCanvas } from "./PersistentCanvas";
import { useRicochetGame } from "../../Hooks/useRicochetGame";

type RicochetGameProps = {
	ws: WebSocket;
};

export const RicochetGame: React.FC<RicochetGameProps> = ({ ws }) => {
	const game = useRicochetGame(ws);
	if (!game) return <div>Loading...</div>;

	const canvasSize = game.getCanvasSize();
	return <PersistentCanvas width={canvasSize.x} height={canvasSize.y} callback={game}></PersistentCanvas>;
};
