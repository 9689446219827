import React from "react";
import "../App.css";
import styles from "./Lobby.module.css";
import { HorizontalSpacer } from "../Shared/Spacer";
import { classNames } from "../../Util/Classnames";

type ArrowButtonProps = {
	onClick: () => void;
	disabled: boolean;
	canEdit: boolean;
	children?: React.ReactNode;
};

const ArrowButton: React.FC<ArrowButtonProps> = ({ onClick, disabled, canEdit, children }) => {
	return canEdit ? (
		<button
			className={classNames({
				[styles.invis_button]: true,
				[styles.square]: true,
				[styles.disabled]: disabled,
			})}
			onClick={disabled ? undefined : onClick}
		>
			{children}
		</button>
	) : (
		<div
			className={classNames({
				[styles.invis_div]: true,
				[styles.square]: true,
				[styles.disabled]: disabled,
			})}
		>
			{children}
		</div>
	);
};

type OptionSelectProps = {
	options: string[];
	label: string;
	index?: number;
	cycle?: boolean;
	canEdit: boolean;
	onSelect: (index: number) => void;
};

export const OptionSelect: React.FC<OptionSelectProps> = ({
	options,
	label,
	index = 0,
	onSelect,
	cycle = false,
	canEdit,
}) => {
	const hasNext = cycle || index < options.length - 1;
	const hasPrev = cycle || index > 0;

	const updateIndex = (delta: number) => () => {
		let newIndex = index + delta;
		if (cycle) {
			newIndex = newIndex % options.length;
		} else if (newIndex < 0 || newIndex >= options.length) {
			return;
		}
		onSelect(newIndex);
	};

	return (
		<HorizontalSpacer centerAcross={true} gap={0} style={{ height: 36, width: 500 }}>
			<label style={{ height: "100%", width: "50%" }}>{label}</label>
			<div className={styles.rounded} style={{ height: "100%", width: "50%" }}>
				<ArrowButton disabled={!hasPrev} canEdit={canEdit} onClick={updateIndex(-1)}>
					{"<"}
				</ArrowButton>
				<div className={styles.option_middle}>{options[index]}</div>
				<ArrowButton disabled={!hasNext} canEdit={canEdit} onClick={updateIndex(1)}>
					{">"}
				</ArrowButton>
			</div>
		</HorizontalSpacer>
	);
};
