import React from "react";
import styles from "./Shared.module.css";

type OverlayProps = {
	text: string;
};

export const Overlay: React.FC<OverlayProps> = ({ text }) => {
	return (
		<div className={styles.overlay}>
			<div className={styles["overlay-content"]}>{text}</div>
		</div>
	);
};
