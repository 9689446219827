import { CommunicationDefinition, GenerateHandlers, PAYLOAD_KEY, Sendable } from "./SocketTypes";
import { SocketAbstract } from "./SocketAbstract";
import { SocketSettings } from "./SocketTypes";
import { SendKeys, InvokeKeys } from "./SocketTypes";

export class SocketClient<
	ToClient extends CommunicationDefinition,
	ToServer extends CommunicationDefinition,
> extends SocketAbstract<undefined, ToClient, ToServer> {
	public readonly websocket: Sendable;

	constructor(websocket: Sendable, toClientHandlers: GenerateHandlers<ToClient>, settings?: Partial<SocketSettings>) {
		super(toClientHandlers, {}, settings);
		this.websocket = websocket;
	}

	public send<K extends SendKeys<ToServer>>(type: K, payload: ToServer[K]["payload"]): void {
		this.sendMessage(this.websocket, {
			type,
			[PAYLOAD_KEY]: payload,
		});
	}

	public async invoke<K extends InvokeKeys<ToServer>>(
		type: K,
		payload: ToServer[K]["payload"],
		timeoutMs: number = this.settings.timeoutMs,
	): Promise<ToServer[K]["return"]> {
		return this._invoke(this.websocket, type, payload, timeoutMs);
	}

	public receiveMessage(message: string) {
		this._receiveMessage(this.websocket, message, undefined);
	}
}
