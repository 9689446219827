import React from "react";
import { SocketClient } from "../Shared/socket/SocketClient";
import { CommunicationDefinition } from "../Shared/socket/SocketTypes";

export function useWebsocket<C extends CommunicationDefinition, S extends CommunicationDefinition>(
	ws: WebSocket,
	socket: SocketClient<C, S>,
	closeOnLifecycle: boolean = true,
) {
	React.useEffect(() => {
		console.log("Using socket!");
		const openFx = () => {
			console.log("Connected to WebSocket server");
		};
		const messageFx = (message: MessageEvent) => socket.receiveMessage(message.data);
		const errorFx = (error: Event) => {
			console.error("WebSocket error:", error);
		};
		ws.addEventListener("open", openFx);
		ws.addEventListener("message", messageFx);
		ws.addEventListener("error", errorFx);

		return () => {
			console.log("No longer using socket");
			ws.removeEventListener("open", openFx);
			ws.removeEventListener("message", messageFx);
			ws.removeEventListener("error", errorFx);
			if (closeOnLifecycle) {
				ws.close();
			}
		};
	}, [socket, ws, closeOnLifecycle]);
}
