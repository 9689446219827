import { Point } from "../math/Vector";

export enum Orientation {
	Horizontal = 0,
	Vertical = 1,
}

export type ShipDescriptor = Readonly<{
	size: Point;
	name: string;
	color: string;
}>;

export type ShipPlacement = Readonly<{
	descriptor: ShipDescriptor;
	position: Point;
	orientation: Orientation;
}>;

export function canPlaceShips(boardSize: Point, placements: ShipPlacement[]) {
	const occupied = new Set<string>();
	for (const placement of placements) {
		for (const { x, y } of getPointsFromPlacement(placement)) {
			if (x < 0 || x >= boardSize.x || y < 0 || y >= boardSize.y) {
				return false;
			}
			const p = serializePoint({ x, y });
			if (occupied.has(p)) {
				return false;
			}
			occupied.add(p);
		}
	}
	return true;
}

export function* getPointsFromPlacement(placement: ShipPlacement): IterableIterator<Point> {
	let [width, height] = [placement.descriptor.size.x, placement.descriptor.size.y];
	if (placement.orientation === Orientation.Vertical) {
		[width, height] = [height, width];
	}
	for (let x = placement.position.x; x < placement.position.x + width; x++) {
		for (let y = placement.position.y; y < placement.position.y + height; y++) {
			yield { x, y };
		}
	}
}

export function* getPointsFromPattern(
	origin: Point,
	pattern: Point[],
	limitX: number,
	limitY: number,
): IterableIterator<Point> {
	for (const { x, y } of pattern) {
		const p = { x: x + origin.x, y: y + origin.y };
		if (p.x < 0 || p.x >= limitX || p.y < 0 || p.y >= limitY) {
			continue;
		}
		yield p;
	}
}

export type ShipInstance = {
	placement: ShipPlacement;
	hp: number;
};

export function serializePoint(point: Point): string {
	return `${point.x},${point.y}`;
}

export type GameState = "WaitingForPlayers" | "ShipPlacement" | "Playing" | "Ended";

export type AttackOption = Readonly<{
	name: string;
	max: number;
	pattern: Point[];
}>;

export type AttackInstance = {
	limited: boolean;
	count: number;
	option: AttackOption;
};

export type GameSettings = Readonly<{
	boardSize: Point;
	shipList: ShipDescriptor[];
	attackList: AttackOption[];
}>;

export type ClientDefinition = {
	["game_state"]: {
		payload: {
			state: GameState;
		};
	};
	["attack_results"]: {
		payload: {
			enemy: boolean;
			results: Array<{
				point: Point;
				hit: boolean;
			}>;
		};
	};
	["game_over"]: {
		payload: {
			winner: boolean;
			enemyPlacements: ShipPlacement[];
		};
	};
	["confirm_board"]: {
		payload: {
			enemy: boolean;
			confirmed: boolean;
		};
	};
	["your_turn"]: {
		payload: undefined;
	};
	["settings"]: {
		payload: {
			settings: GameSettings;
		};
	};
	["hover_attack"]: {
		payload: {
			points: Point[];
		};
	};
};

export type ServerDefinition = {
	["attack"]: {
		payload: {
			index: number;
			point: Point;
		};
	};
	["board"]: {
		payload: {
			placements: ShipPlacement[];
		};
	};
	["hover_attack"]: {
		payload: {
			index: number;
			point: Point;
		};
	};
};
