import React from "react";
import { SocketClient } from "../Shared/socket/SocketClient";
import { ClientDefinition, ServerDefinition } from "../Shared/game/RicochetGameTypes";
import { RicochetGameClient } from "../Shared/game/RicochetGameClient";
import { useWebsocket } from "./useWebsocket";

export function useRicochetGame(ws: WebSocket) {
	const [game, setGame] = React.useState<RicochetGameClient | undefined>();
	const gameRef = React.useRef<RicochetGameClient | undefined>(undefined);

	const socket = React.useMemo(
		() =>
			new SocketClient<ClientDefinition, ServerDefinition>(ws, {
				begin: (payload) => {
					console.log("RECEIVED INIT!");
					const g = new RicochetGameClient(payload);
					gameRef.current = g;
					setGame(g);
					g.start(socket);
					return true;
				},
				hit: (payload) => gameRef.current?.receivePlayerHit(payload),
				inputs: (payload) => gameRef.current?.receivePlayerInputs(payload),
				pos: (payload) => gameRef.current?.receivePlayerPositions(payload),
				projectile: (payload) => gameRef.current?.receiveProjectiles(payload),
			}),
		[ws],
	);

	useWebsocket(ws, socket);

	return game;
}
