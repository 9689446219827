import { SocketSettings, Validators } from "./SocketTypes";
import { SendKeys, InvokeKeys } from "./SocketTypes";
import {
	GenerateMessages,
	CommunicationDefinition,
	GenerateInvokeHandlers,
	MessageFromDefinition,
	ReturnMessageFromDefinition,
	PAYLOAD_KEY,
	GenerateServerHandlers,
	Sendable,
} from "./SocketTypes";

function weakRandomHash(): string {
	return Math.random().toString(36).substring(2, 10);
}

const INVOKE_OUTBOUND_TYPE = "out" as const;
const INVOKE_INBOUND_TYPE = "in" as const;
type InvokeMessage = {
	type: typeof INVOKE_OUTBOUND_TYPE | typeof INVOKE_INBOUND_TYPE;
	id: string;
	message: {
		type: string;
	};
};
type InvokeInbound<Definition extends CommunicationDefinition, K extends keyof Definition> = {
	type: typeof INVOKE_INBOUND_TYPE;
	id: string;
	message: ReturnMessageFromDefinition<Definition, K>;
};

type InvokeOutbound<Definition extends CommunicationDefinition, K extends keyof Definition> = {
	type: typeof INVOKE_OUTBOUND_TYPE;
	id: string;
	message: MessageFromDefinition<Definition, K>;
};

type Send<Definition extends CommunicationDefinition> = {
	[K in keyof Definition]: Definition[K] extends { return: unknown } ? never : Definition[K];
};
const DefaultSocketSettings: SocketSettings = {
	timeoutMs: 10 * 1000,
};

export abstract class SocketAbstract<
	Client,
	Inbound extends CommunicationDefinition,
	Outbound extends CommunicationDefinition,
> {
	protected inboundHandlers: GenerateServerHandlers<Inbound, Client>;
	protected validators: Validators<Inbound>;
	protected activePromises: Map<
		string,
		{
			resolve: (value: Inbound[keyof Inbound]["return"]) => void;
			reject: (reason?: string) => void;
		}
	>;
	protected settings: SocketSettings;

	constructor(
		inboundHandlers: GenerateServerHandlers<Inbound, Client>,
		validators: Validators<Inbound> = {},
		settings: Partial<SocketSettings> = {},
	) {
		this.inboundHandlers = inboundHandlers;
		this.validators = validators;
		this.activePromises = new Map();
		this.settings = {
			...DefaultSocketSettings,
			...settings,
		};
	}

	protected keyOf(message: InvokeMessage, promisePrefix: string = ""): string {
		return `${promisePrefix}${message.id}-${message.message.type}`;
	}

	protected sendMessage(
		socket: Sendable,
		message:
			| GenerateMessages<Send<Outbound>>
			| {
					[K in keyof Outbound]: InvokeOutbound<Outbound, K>;
			  }[keyof Outbound]
			| {
					[K in keyof Inbound]: InvokeInbound<Inbound, K>;
			  }[keyof Inbound],
	) {
		socket.send(JSON.stringify(message));
		//console.log("Sent", message);
	}

	protected _send<K extends SendKeys<Outbound>>(type: K, socket: Sendable, payload: Outbound[K]["payload"]): void {
		this.sendMessage(socket, {
			type,
			[PAYLOAD_KEY]: payload,
		});
	}

	protected makeInvokeInboundMessage<K extends keyof Inbound>(
		invokeOutbound: InvokeOutbound<Inbound, K>,
		result: Inbound[K]["return"],
	): InvokeInbound<Inbound, K> {
		return {
			type: INVOKE_INBOUND_TYPE,
			id: invokeOutbound.id,
			message: {
				type: invokeOutbound.message.type,
				[PAYLOAD_KEY]: result,
			},
		};
	}

	protected makeInvokeOutboundMessage<K extends keyof Outbound>(
		message: MessageFromDefinition<Outbound, K>,
	): InvokeOutbound<Outbound, K> {
		return {
			type: INVOKE_OUTBOUND_TYPE,
			id: weakRandomHash(),
			message,
		};
	}

	protected async _invoke<K extends InvokeKeys<Outbound>>(
		socket: Sendable,
		type: K,
		payload: Outbound[K]["payload"],
		timeoutMs: number = this.settings.timeoutMs,
		promisePrefix: string = "",
	): Promise<Outbound[K]["return"]> {
		const invokePayload = this.makeInvokeOutboundMessage({
			type,
			[PAYLOAD_KEY]: payload,
		});

		const promiseKey = this.keyOf(invokePayload, promisePrefix);

		if (this.activePromises.has(promiseKey)) {
			throw new Error("Promise already exists");
		}

		let timeout: NodeJS.Timeout | undefined;
		const responsePromise = new Promise<Outbound[K]["return"]>((resolve, reject) => {
			timeout = setTimeout(() => {
				console.log("Rejecting promise due to timeout");
				reject("Promise timed out");
			}, timeoutMs);
			this.activePromises.set(promiseKey, { resolve, reject });
		}).finally(() => {
			this.activePromises.delete(promiseKey);
			if (timeout) {
				clearTimeout(timeout);
				timeout = undefined;
			}
		});

		this.sendMessage(socket, invokePayload);

		return responsePromise;
	}

	protected onInvokeInbound<K extends keyof Outbound & string>(
		invokeInbound: InvokeInbound<Outbound, K>,
		promisePrefix: string = "",
	) {
		const { message } = invokeInbound;
		const promise = this.activePromises.get(this.keyOf(invokeInbound, promisePrefix));
		if (promise) {
			const validator = this.validators[message.type];
			const payload = validator ? validator(message[PAYLOAD_KEY]) : message[PAYLOAD_KEY];
			promise.resolve(payload);
		} else {
			throw new Error("Promise not found");
		}
	}

	protected onInvokeOutbound<K extends keyof GenerateInvokeHandlers<Inbound>>(
		socket: Sendable,
		invokeOutbound: InvokeOutbound<Inbound, K>,
	) {
		const { message } = invokeOutbound;
		const handler = this.inboundHandlers[message.type] as GenerateInvokeHandlers<Inbound>[K];
		const result = handler(message[PAYLOAD_KEY]);
		const returnMessage = this.makeInvokeInboundMessage(invokeOutbound, result);
		this.sendMessage(socket, returnMessage);
	}

	protected _receiveMessage(socket: Sendable, message: string, client: Client, promisePrefix: string = "") {
		try {
			const parsed = JSON.parse(message);
			//console.log("Received", parsed);
			const { type } = parsed;
			if (type === undefined) {
				throw new Error("Message type is undefined");
			}

			if (type === INVOKE_OUTBOUND_TYPE) {
				this.onInvokeOutbound(socket, parsed);
				return;
			}

			if (type === INVOKE_INBOUND_TYPE) {
				this.onInvokeInbound(parsed, promisePrefix);
				return;
			}

			const handler = this.inboundHandlers[type];
			if (!handler) {
				throw new Error(`No handler found for message type: ${type}`);
			}
			const validator = this.validators[type];
			const payload = validator ? validator(parsed[PAYLOAD_KEY]) : parsed[PAYLOAD_KEY];
			handler(payload, client);
		} catch (error) {
			console.error("Error on read message", { error });
		}
	}
}
