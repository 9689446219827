import React from "react";
import styles from "./Attack.module.css";
import { PatternButton } from "./PatternButton";
import { AttackInstance } from "../../../Shared/battleship/BattleshipSharedTypes";

interface AttackSelectProps {
	instances: AttackInstance[];
	indexSelected: number;
	onSelect?: (index: number) => void;
}

export const AttackSelect: React.FC<AttackSelectProps> = ({ instances, indexSelected, onSelect }) => {
	const attemptSelect = React.useCallback(
		(index: number) => {
			if (index === indexSelected) return;
			if (onSelect === undefined) return;
			onSelect(index);
		},
		[indexSelected, onSelect],
	);

	return (
		<div className={styles["attack-option-container"]}>
			{instances.map((instance, index) => (
				<PatternButton
					key={index}
					pattern={instance.option.pattern}
					title={instance.option.name}
					subtitle={instance.limited ? `Count: ${instance.count}` : "Unlimited"}
					selected={index === indexSelected}
					disabled={instance.limited && instance.count <= 0}
					index={index}
					onClick={attemptSelect}
				/>
			))}
		</div>
	);
};
