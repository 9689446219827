import { ShipPlacement, ShipDescriptor } from "../../Shared/battleship/BattleshipSharedTypes";
import { LobbyState } from "../../Hooks/useLobby";

export type PointMap = Map<string, boolean>;
export type PlacingShips = {
	placement?: ShipPlacement;
	descriptor: ShipDescriptor;
}[];

export const CONSTANTS = {
	TILE_SIZE: 36,
};

export const getCurrentPlayer = (state: LobbyState) => state.players.find((p) => p.id === state.idPlayer);
