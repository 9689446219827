import React from "react";
import "../App.css";
import { VerticalSpacer } from "../Shared/Spacer";
import { RoundedButton } from "../Shared/RoundedButton";
import { useSession } from "../../Hooks/useSession";

type LobbyMenuProps = {
	title: string;
	setSocket: React.Dispatch<React.SetStateAction<WebSocket | undefined>>;
};

export const LobbyMenu: React.FC<LobbyMenuProps> = ({ title, setSocket }) => {
	const { attemptJoinSession, attemptCreateSession } = useSession({
		setSocket,
		sessionType: "lobby",
	});

	return (
		<VerticalSpacer centerAcross={true}>
			<h1>{title}</h1>
			<RoundedButton onClick={async () => await attemptCreateSession(true)}>Create session</RoundedButton>
			<RoundedButton onClick={async () => await attemptJoinSession()}>Join session</RoundedButton>
		</VerticalSpacer>
	);
};
