import React from "react";
import { RoundedButton } from "../Components/Shared/RoundedButton";
import { Helmet } from "react-helmet";
import "../Components/App.css";

export const NotFound: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>404: Not found</title>
			</Helmet>
			<div className="App-center-fullscreen">
				<h1>{"There's nothing here!"}</h1>
				<p>
					<i>Better luck next time</i>
				</p>
				<a href="/">
					<RoundedButton>Take me home</RoundedButton>
				</a>
			</div>
		</>
	);
};

export default NotFound;
