import React from "react";
import "../App.css";
import "./Lobby.module.css";
import { MODIFIABLE_KEYS } from "../../Shared/game/RicochetGameTypes";
import { OptionRows } from "./OptionRows";
import { mapObject } from "../../Shared/math/Object";
import { SETTINGS_ROWS, STAGES } from "../../Shared/game/Data";
import { PersistentCanvas } from "../Ricochet/PersistentCanvas";
import { Render } from "../../Shared/game/RenderHelpers";
import { VerticalSpacer } from "../Shared/Spacer";

const UI_SETTINGS_ROWS = mapObject(SETTINGS_ROWS, ({ label, values, valueLabels }) => ({
	label,
	options: valueLabels ?? values.map((v) => v.toString()),
}));

type RicochetOptionsProps = {
	selections: number[];
	onSelect: (rowIndex: number, optionIndex: number) => void;
	canEdit: boolean;
};

export const RicochetOptions: React.FC<RicochetOptionsProps> = ({ selections, onSelect, canEdit }) => {
	const stage = React.useMemo(() => STAGES[selections[0] ?? 0] ?? STAGES[0], [selections]);

	const canvasScale = 300 / stage.size.y;

	const canvasCallback = React.useMemo(() => {
		console.log("canvasCallback", stage);
		return {
			connect: (canvas: HTMLCanvasElement) => {
				const ctx = canvas.getContext("2d");
				if (!ctx) return;
				ctx.reset();
				ctx.scale(canvasScale, canvasScale);
				Render.Stage(ctx, stage, 1);
			},
			disconnect: () => {},
		};
	}, [stage, canvasScale]);

	return (
		<VerticalSpacer centerAcross={true}>
			<PersistentCanvas
				width={stage.size.x * canvasScale}
				height={stage.size.y * canvasScale}
				callback={canvasCallback}
			/>
			<OptionRows
				keys={MODIFIABLE_KEYS}
				rows={UI_SETTINGS_ROWS}
				selections={selections}
				onSelect={onSelect}
				cycle={false}
				canEdit={canEdit}
			/>
		</VerticalSpacer>
	);
};
