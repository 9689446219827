import React from "react";
import "../App.css";
import { HorizontalSpacer, VerticalSpacer } from "../Shared/Spacer";
import { useLobby } from "../../Hooks/useLobby";
import { EmptyPlayer, LobbyPlayer } from "./LobbyPlayer";
import { RoundedButton } from "../Shared/RoundedButton";
import { getCurrentPlayer } from "./Types";
import { RicochetOptions } from "./RicochetOptions";

type LobbyProps = {
	ws: WebSocket;
	title: string;
	setTransfer: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Lobby: React.FC<LobbyProps> = ({ ws, title, setTransfer }) => {
	const { state, socket, onSelect } = useLobby(ws, setTransfer);
	const selections = state.settingsSelection;
	return (
		<>
			<h1>{title}</h1>
			<HorizontalSpacer gap={16}>
				<VerticalSpacer centerAcross={true} gap={8}>
					{state.players.map((player, index) => (
						<LobbyPlayer key={player.id} player={player} index={index} />
					))}
					{Array.from({ length: state.settings.maxPlayers - state.players.length }, (_, index) => (
						<EmptyPlayer key={`empty_${index}`} />
					))}
					<RoundedButton
						onClick={() => {
							const player = getCurrentPlayer(state);
							if (!player) return;
							socket.send("player_update", {
								ready: !player.state.client.ready,
							});
						}}
					>
						Ready
					</RoundedButton>
				</VerticalSpacer>
				<RicochetOptions
					selections={selections}
					onSelect={onSelect}
					canEdit={state.idPlayer === state.players[0]?.id}
				/>
			</HorizontalSpacer>
		</>
	);
};
