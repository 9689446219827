import Box2D from "box2dweb";
import { ObjectHandler, ObjectInstance } from "./ObjectHandler";
import { ProjectileDescriptor, ProjectileHandler, ProjectileInstance } from "./ProjectileHandler";
import { PlayerHandler } from "./PlayerHandler";
import { BodyProps, InstanceId } from "./InstanceHandler";
import { PlayerInput } from "./RicochetGameTypes";
import { STAGES, StageData } from "./Data";
import { MutableVector2, Vector2 } from "../math/Vector";
import { Delta, StateManager } from "../StateManager";
import { ContactInfo } from "./RicochetContactListener";
import { RicochetGameSettings } from "./RicochetGameTypes";

export class RicochetGame {
	protected world: Box2D.Dynamics.b2World;
	protected projectileHandler: ProjectileHandler;
	protected playerHandler: PlayerHandler;
	protected objectHandler: ObjectHandler;
	protected frame: number;
	protected readonly timeStep: number;
	protected settings: RicochetGameSettings;
	protected stage: Readonly<StageData>;

	public static readonly PROJECTILE_RADIUS = 4;
	public static readonly PLAYER_RADIUS = 10;

	constructor(settings: RicochetGameSettings) {
		this.world = new Box2D.Dynamics.b2World(new Box2D.Common.Math.b2Vec2(0, 0), false);
		this.projectileHandler = new ProjectileHandler(
			this.world,
			RicochetGame.PROJECTILE_RADIUS / settings.pixelsPerUnit,
			settings.maxBounces,
		);
		this.playerHandler = new PlayerHandler(
			this.world,
			RicochetGame.PLAYER_RADIUS / settings.pixelsPerUnit,
			settings.lives,
		);
		this.objectHandler = new ObjectHandler(this.world);
		this.frame = 0;
		this.timeStep = 1 / settings.tickRate;
		this.settings = settings;
		const stage = STAGES[settings.stageIndex];
		if (!stage) throw new Error("Invalid stage index");
		this.stage = stage;
		this.objectHandler.createAll(
			ObjectHandler.resizeDescriptors(
				[...stage.objects, ...ObjectHandler.makeWallDescriptors(stage.size)],
				settings.pixelsPerUnit,
			),
		);
	}

	public destroy() {
		let body = this.world.GetBodyList();
		while (body) {
			const nextBody = body.GetNext();
			this.world.DestroyBody(body);
			body = nextBody;
		}
	}

	public time(): number {
		return this.frame * this.timeStep;
	}

	public step() {
		const dt = this.timeStep;
		this.frame++;
		this.playerHandler.step(dt, this.settings.playerSpeed / this.settings.pixelsPerUnit, this.toWorldSpace.bind(this));
		this.world.Step(dt, 2, 2);
		this.playerHandler.clean();
		this.projectileHandler.clean();
		this.objectHandler.clean();
	}

	public desiredFrame(time: number): number {
		return Math.ceil(time / this.timeStep);
	}

	public stepTo(time: number) {
		const desired = this.desiredFrame(time);
		//if (desired - this.frame > 5 / this.timeStep) throw new Error("Too large step");
		while (this.frame < desired && !this.isGameOver()) {
			this.step();
		}
	}

	public getCanvasSize(): Vector2 {
		return this.stage.size;
	}

	public toWorldSpace(pos: Vector2): Vector2 {
		return Vector2.mul(pos, 1 / this.settings.pixelsPerUnit);
	}

	public applyPlayerInput(id: InstanceId, input: Delta<PlayerInput>) {
		const player = this.playerHandler.get(id);
		if (!player || player.hp <= 0) return;
		StateManager.applyDelta(player.input, input);
	}

	public projectileHitPlayer(idProjectile: InstanceId, idPlayer: InstanceId) {
		this.projectileHandler.delete(idProjectile);
		const player = this.playerHandler.get(idPlayer);
		if (!player) return;
		PlayerHandler.dealDamage(player, 1);
	}

	public projectileHitWall(proj: ProjectileInstance, wall: ObjectInstance, info: ContactInfo) {
		proj.bounces++;
		if (proj.bounces > this.settings.maxBounces) {
			this.projectileHandler.delete(proj.id);
			return;
		}
		const dir = proj.direction;
		MutableVector2.reflect(dir, info.normal);
		proj.body.SetLinearVelocity(
			new Box2D.Common.Math.b2Vec2(
				(dir.x * this.settings.projectileSpeed) / this.settings.pixelsPerUnit,
				(dir.y * this.settings.projectileSpeed) / this.settings.pixelsPerUnit,
			),
		);
		proj.angle = Vector2.angle(dir);
	}

	public isGameOver(): boolean {
		for (const [, player] of this.playerHandler.iterator()) {
			if (player.hp > 0) return false;
		}
		return true;
	}

	public getTimeStep(): number {
		return this.timeStep;
	}

	public spawnProjectile(props: ProjectileDescriptor & BodyProps, id?: InstanceId) {
		return this.projectileHandler.create(props, id);
	}
}
