import React from "react";
import { Link } from "react-router-dom";
import { RoundedButton } from "../Components/Shared/RoundedButton";
import { Helmet } from "react-helmet";
import "../Components/App.css";
import { VerticalSpacer } from "../Components/Shared/Spacer";

export const Homepage: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Some Silly Things home page</title>
				<meta name="description" content="A website with various tools and projects as a sort of portfolio" />
				<link rel="canonical" href="/" />
			</Helmet>
			<VerticalSpacer centerAcross={true}>
				<h1>Some Silly Things</h1>
				<Link to="/ricochet">
					<RoundedButton style={{ minWidth: 300 }}>Ricochet Tanks</RoundedButton>
				</Link>
				<Link to="/battleship">
					<RoundedButton style={{ minWidth: 300 }}>Battleship</RoundedButton>
				</Link>
				<a href="/chat">
					<RoundedButton style={{ minWidth: 300 }}>Chat</RoundedButton>
				</a>
			</VerticalSpacer>
		</>
	);
};

export default Homepage;
