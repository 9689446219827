export type Delta<T> = {
	[K in keyof T]?: T[K] extends StateManager<infer S> ? Delta<S> : T[K] extends object ? Delta<T[K]> : T[K];
};

export type Statelike = Record<string | number, unknown>;

type DeepReadonly<T> = {
	readonly [P in keyof T]: DeepReadonly<T[P]>;
};

function applyDelta(state: Statelike, delta: Delta<Statelike> | undefined): boolean {
	if (!delta) return false;
	let changed = false;
	for (const [key, value] of Object.entries(delta)) {
		if (!Object.hasOwn(state, key)) continue;
		if (typeof value === "object") {
			const currentValue = state[key];
			if (currentValue instanceof StateManager) {
				changed = changed || currentValue.applyDelta(value as Statelike);
			} else {
				changed = changed || applyDelta(currentValue as Statelike, value as Statelike);
			}
		} else {
			state[key] = value;
			changed = true;
		}
	}
	return changed;
}

function computeDelta(state: Statelike, newState: Statelike, delta?: Delta<Statelike>): Delta<Statelike> | undefined {
	let changed = false;
	const result: Delta<Statelike> = delta || {};
	for (const [key, value] of Object.entries(newState)) {
		if (!Object.hasOwn(state, key)) continue;
		if (state[key] !== value) {
			if (typeof value === "object") {
				let subDelta;
				const currentValue = state[key];
				if (currentValue instanceof StateManager) {
					subDelta = currentValue.update(value as Statelike);
				} else {
					subDelta = computeDelta(state[key] as Statelike, value as Statelike, result[key] as Statelike | undefined);
				}
				if (subDelta) {
					changed = true;
					result[key] = subDelta;
				}
			} else {
				changed = true;
				result[key] = value;
				state[key] = value;
			}
		}
	}
	return changed ? result : undefined;
}

function jsonDelta(lastJSON: string, newJSON: string): Delta<Statelike> | undefined {
	const lastState = JSON.parse(lastJSON);
	const newState = JSON.parse(newJSON);
	return computeDelta(lastState, newState);
}

function recursiveDeleteObject(obj: Statelike, path: string[]): boolean {
	if (path.length === 0) return true;
	const [key, ...rest] = path;
	if (!Object.hasOwn(obj, key)) return Object.keys(obj).length === 0;
	const shouldDelete = recursiveDeleteObject(obj[key] as Statelike, rest);
	if (shouldDelete) {
		delete obj[key];
		return Object.keys(obj).length === 0;
	}
	return false;
}

type ParentStateManager = {
	parent: StateManager<Statelike>;
	path: string[];
};

export class StateManager<T extends Statelike> {
	static applyDelta<K extends Statelike>(state: K, delta: Delta<K> | undefined): void {
		applyDelta(state, delta);
	}

	protected state: T;
	private delta: Delta<T> | undefined;
	private parents: ParentStateManager[];
	protected children: StateManager<Statelike>[];
	private lastJSON: string;

	constructor(initialState: T) {
		this.state = initialState;
		this.delta = undefined;
		this.parents = [];
		this.children = [];
		this.getChildren(this.state, []);
		this.lastJSON = this.json();
	}

	public json(replacer?: (key: string, value: unknown) => unknown): string {
		return JSON.stringify(this.state, (key: string, value: unknown) => {
			if (value instanceof StateManager) {
				return value.json(replacer);
			}
			return replacer ? replacer(key, value) : value;
		});
	}

	private getChildren(state: Statelike, path: string[]) {
		for (const [key, value] of Object.entries(state)) {
			if (typeof value === "object") {
				if (value instanceof StateManager) {
					value.setParent({ parent: this, path: [...path, key] });
				} else {
					this.getChildren(value as Statelike, [...path, key]);
				}
			}
		}
	}

	protected handleChildClear(path: string[]) {
		if (this.delta === undefined) return;
		const shouldDelete = recursiveDeleteObject(this.delta, path);
		if (shouldDelete) {
			this.delta = undefined;
		}
	}

	protected handleChildUpdate(delta: Statelike | undefined, path: string[]) {
		if (delta === undefined) return this.handleChildClear(path);
		if (this.delta === undefined) {
			this.delta = {};
		}
		let obj: Statelike = this.delta;
		path.forEach((field, index) => {
			if (index === path.length - 1) {
				obj[field] = delta;
			} else {
				if (obj[field] === undefined) {
					obj[field] = {};
				}
				obj = obj[field] as Statelike;
			}
		});
	}

	public update(newState: Delta<T> | undefined): Delta<T> | undefined {
		if (newState === undefined) return this.delta;
		this.delta = computeDelta(this.state, newState, this.delta) as Delta<T>;
		if (this.delta !== undefined) {
			for (const { parent, path } of this.parents) {
				parent.handleChildUpdate(this.delta, path);
			}
		}
		return this.delta;
	}

	public applyDelta(delta: Delta<T> | undefined): boolean {
		return applyDelta(this.state, delta);
	}

	public getDelta(): Delta<T> | undefined {
		return this.delta;
	}

	public getJSONDelta(): Delta<T> | undefined {
		const newJSON = this.json();
		const delta = jsonDelta(this.lastJSON, newJSON);
		this.lastJSON = newJSON;
		return delta as Delta<T> | undefined;
	}

	public clearDelta() {
		this.delta = undefined;
		for (const child of this.children) {
			child.clearDelta();
		}
		for (const { parent, path } of this.parents) {
			parent.handleChildClear(path);
		}
	}

	public getState(): DeepReadonly<T> {
		return this.state;
	}

	protected setParent(parent: ParentStateManager) {
		this.parents.push(parent);
		parent.parent.children.push(this);
	}
}
