import React from "react";
import styles from "./Attack.module.css";
import { GridPattern } from "./GridPattern";
import { Point } from "../../../Shared/math/Vector";

interface PatternButtonProps {
	size?: Point;
	pattern?: Point[];
	title?: string;
	subtitle?: string;
	patternColor?: string;
	selected?: boolean;
	highlighted?: boolean;
	disabled?: boolean;
	index: number;
	onClick?: (index: number) => void;
}

export const PatternButton: React.FC<PatternButtonProps> = ({
	size,
	pattern,
	title = "",
	subtitle = "",
	patternColor,
	selected,
	highlighted,
	disabled,
	index,
	onClick,
}) => {
	return (
		<button
			className={`${styles["attack-option-button"]} ${selected ? styles.selected : ""} ${highlighted ? styles.highlighted : ""} ${disabled ? styles.disabled : ""}`}
			onClick={onClick && (() => onClick(index))}
		>
			<GridPattern size={size} pattern={pattern} color={patternColor} />
			<div className={styles["option-details"]}>
				<div className={styles["option-name"]}>{title}</div>
				{subtitle && <div className={styles["option-count"]}>{subtitle}</div>}
			</div>
		</button>
	);
};
