import { Delta } from "../StateManager";
import { Vector2 } from "../math/Vector";
import { BodyProps, Encoded, InstanceId } from "./InstanceHandler";
import { PlayerDescriptor } from "./PlayerHandler";
import { ProjectileDescriptor } from "./ProjectileHandler";

export type RicochetGameSettings = {
	stageIndex: number;
	tickRate: number;
	pixelsPerUnit: number;
	projectileSpeed: number;
	playerSpeed: number;
	maxBounces: number;
	lives: number;
};

export const MODIFIABLE_KEYS = ["stageIndex", "projectileSpeed", "playerSpeed", "maxBounces", "lives"] as const;

export type ModifiableKeys = (typeof MODIFIABLE_KEYS)[number];

export type ModifiableSettingsOptions = {
	[key in ModifiableKeys]: {
		label: string;
		values: RicochetGameSettings[key][];
		valueLabels?: string[];
		defaultIndex: number;
	};
};

export type ModifiableSettingsSelections = number[];

export function getSettingsFromSelection(
	options: ModifiableSettingsOptions,
	selections: ModifiableSettingsSelections,
): Pick<RicochetGameSettings, ModifiableKeys> {
	return Object.fromEntries(
		MODIFIABLE_KEYS.map((key, i) => {
			const { values, defaultIndex } = options[key];
			const sel = selections[i] ?? defaultIndex;
			const value = values[sel] ?? values[defaultIndex];
			return [key, value];
		}),
	) as Pick<RicochetGameSettings, ModifiableKeys>;
}

export function getDefaultSelections(options: ModifiableSettingsOptions): ModifiableSettingsSelections {
	return Object.entries(options).map(([, { defaultIndex }]) => defaultIndex);
}

export type WritablePlayerState = {
	pos: Vector2;
	vel: Vector2;
	rot: number;
};

export type PlayerInput = {
	move: Vector2;
	mouse: Vector2;
};

export type GameStateDescription = {
	players: Encoded<PlayerDescriptor>;
	projectiles: Encoded<ProjectileDescriptor>;
};

export type ClientDefinition = {
	["begin"]: {
		payload: {
			id: InstanceId;
			settings: RicochetGameSettings;
			desc: GameStateDescription;
		};
		return: boolean;
	};
	["hit"]: {
		payload: {
			idPlayer: InstanceId;
			idProjectile: InstanceId;
		};
	};
	["inputs"]: {
		payload: [InstanceId, Delta<PlayerInput>][];
	};
	["pos"]: {
		payload: [InstanceId, Vector2][];
	};
	["projectile"]: {
		payload: [InstanceId, ProjectileDescriptor & BodyProps][];
	};
};

export type ServerDefinition = {
	["input"]: {
		payload: {
			frame: number;
			input: Delta<PlayerInput>;
		};
	};
	["shoot"]: {
		payload: {
			frame: number;
			pos: Vector2;
			angle: number;
		};
	};
};
