import React from "react";
import "../App.css";
import { RicochetGame } from "./RicochetGame";
import { Lobby } from "../Lobby/Lobby";

type RicochetLobbyProps = {
	ws: WebSocket;
};

export const RicochetLobby: React.FC<RicochetLobbyProps> = ({ ws }) => {
	const [transfer, setTransfer] = React.useState(false);

	React.useEffect(() => {
		return () => {
			ws.close();
		};
	}, [ws]);

	if (!transfer) {
		return <Lobby title={"Ricochet Tanks Lobby"} ws={ws} setTransfer={setTransfer} />;
	} else {
		return (
			<>
				<h1>Ricochet Tanks</h1>
				<RicochetGame ws={ws} />
			</>
		);
	}
};
