import React from "react";
import styles from "./Board.module.css";
import { Tile, TileType } from "./Tile";
import { ShipPlacement, serializePoint } from "../../../Shared/battleship/BattleshipSharedTypes";
import { PointMap } from "../Types";
import { HorizontalSpacer } from "../../Shared/Spacer";

interface HeaderTileProps {
	display: string;
}

const HeaderTile: React.FC<HeaderTileProps> = ({ display }) => {
	return <div className={styles["header-cell"]}>{display}</div>;
};

interface GridProps {
	x: number;
	y: number;
	selectedSet?: Set<string>;
	hitMap?: PointMap;
	shipMap?: Map<string, ShipPlacement>;
	tileColor?: string;
	tileHitColor?: string;
	onClick?: (x: number, y: number) => void;
	onHover?: (x: number, y: number) => void;
	onMouseLeaveGrid?: () => void;
	contentRight?: React.ReactNode;
	contentBelow?: React.ReactNode;
	contentAbove?: React.ReactNode;
}

const TILE_SIZE = (n: number) => n * 36 + n - 1;

export const GridFC: React.FC<GridProps> = ({
	x,
	y,
	selectedSet,
	hitMap,
	shipMap,
	tileColor,
	tileHitColor,
	onClick,
	onHover,
	onMouseLeaveGrid,
	contentRight,
	contentBelow,
	contentAbove,
}) => {
	const firstCharCode = "A".charCodeAt(0);
	const columnHeaders = Array.from({ length: y }, (_, i) => String.fromCharCode(firstCharCode + i));
	const rowHeaders = Array.from({ length: x }, (_, i) => (i + 1).toString());

	return (
		<div>
			<div style={{ marginLeft: 36, width: 36 * x, display: "flex", justifyContent: "center" }}>{contentAbove}</div>
			<HorizontalSpacer>
				<div className={styles["grid-container"]} onMouseLeave={onMouseLeaveGrid}>
					<div className={styles["header-row"]}>
						<div className={styles["empty-cell"]}></div>
						{columnHeaders.map((column, index) => (
							<HeaderTile key={`column-header-${index}`} display={column} />
						))}
					</div>
					{rowHeaders.map((row, rowIndex) => (
						<div key={`row-${rowIndex}`} className={styles["grid-row"]}>
							<HeaderTile key={`row-header-${rowIndex}`} display={row} />
							{columnHeaders.map((column, colIndex) => {
								const point = serializePoint({ x: colIndex, y: rowIndex });
								const hit = hitMap?.has(point);
								const type = hit ? (hitMap?.get(point) ? TileType.Hit : TileType.Miss) : TileType.Empty;
								let finalTileColor = shipMap?.get(point)?.descriptor.color || tileColor;
								if (type === TileType.Hit && finalTileColor === tileColor) {
									finalTileColor = tileHitColor;
								}
								return (
									<Tile
										key={`cell-${column}-${row}`}
										x={colIndex}
										y={rowIndex}
										type={type}
										color={finalTileColor}
										selected={selectedSet?.has(point) || false}
										onClick={onClick}
										onHover={onHover}
									/>
								);
							})}
						</div>
					))}
				</div>
				<div style={{ marginTop: 36, height: TILE_SIZE(y), minWidth: 150 }}>{contentRight}</div>
			</HorizontalSpacer>
			<HorizontalSpacer
				center={true}
				style={{
					marginLeft: 36,
					width: TILE_SIZE(x),
					marginTop: 18,
					minHeight: 70,
				}}
			>
				{contentBelow}
			</HorizontalSpacer>
		</div>
	);
};

export const Grid = React.memo(GridFC);
