import { toRadians } from "../math/Angle";
import { Vector2 } from "../math/Vector";
import { ObjectDescriptor } from "./ObjectHandler";
import { ModifiableSettingsOptions } from "./RicochetGameTypes";

export type StageData = {
	name: string;
	size: Vector2;
	objects: ObjectDescriptor[];
	spawns: Vector2[];
};

export const STAGES: Readonly<StageData[]> = [
	((size: Vector2) => {
		const t = 40;
		return {
			name: "Zigzag",
			size,
			objects: [
				{
					type: "rectangle",
					pos: Vector2.new(size.x / 2, 0),
					size: Vector2.new(t * 5, t * 5),
					angle: toRadians(45),
				},
				{
					type: "rectangle",
					pos: Vector2.new(size.x / 2, size.y),
					size: Vector2.new(t * 5, t * 5),
					angle: toRadians(45),
				},
				/*				{
					type: "triangle",
					pos: Vector2.new(size.x / 2 + (t * 3) / 2, size.y - (t * 3) / 2),
					size: Vector2.new(t * 3, t * 3),
				},
				{
					type: "triangle",
					pos: Vector2.new(size.x / 2 - (t * 3) / 2, size.y - (t * 3) / 2),
					size: Vector2.new(t * 3, t * 3),
					angle: toRadians(-90),
				},
				{
					type: "triangle",
					pos: Vector2.new(size.x / 2 + (t * 3) / 2, (t * 3) / 2),
					size: Vector2.new(t * 3, t * 3),
					angle: toRadians(90),
				},
				{
					type: "triangle",
					pos: Vector2.new(size.x / 2 - (t * 3) / 2, (t * 3) / 2),
					size: Vector2.new(t * 3, t * 3),
					angle: toRadians(180),
				},*/
				{
					type: "rectangle",
					pos: Vector2.new(size.x / 2 + t * 3, size.y / 2),
					size: Vector2.new(t * 4, t * 2),
				},
				{
					type: "rectangle",
					pos: Vector2.new(size.x / 2 - t * 3, size.y / 2),
					size: Vector2.new(t * 4, t * 2),
				},
				{
					type: "rectangle",
					pos: Vector2.new(size.x / 2 + t * 6, size.y / 2 - t * 3),
					size: Vector2.new(t * 2, t * 4),
				},
				{
					type: "rectangle",
					pos: Vector2.new(size.x / 2 - t * 6, size.y / 2 + t * 3),
					size: Vector2.new(t * 2, t * 4),
				},
				{
					type: "triangle",
					pos: Vector2.new(size.x / 2 + t * 4, size.y / 2 - t * 2),
					size: Vector2.new(t * 2, t * 2),
					angle: toRadians(-90),
				},
				{
					type: "triangle",
					pos: Vector2.new(size.x / 2 - t * 6, size.y / 2),
					size: Vector2.new(t * 2, t * 2),
					angle: toRadians(-90),
				},
				{
					type: "triangle",
					pos: Vector2.new(size.x / 2 - t * 4, size.y / 2 + t * 2),
					size: Vector2.new(t * 2, t * 2),
					angle: toRadians(90),
				},
				{
					type: "triangle",
					pos: Vector2.new(size.x / 2 + t * 6, size.y / 2),
					size: Vector2.new(t * 2, t * 2),
					angle: toRadians(90),
				},
				{
					type: "rectangle",
					pos: Vector2.new(t * 3, t * 3.5),
					size: Vector2.new(t * 3, t * 3),
					angle: toRadians(45),
				},
				{
					type: "rectangle",
					pos: Vector2.new(size.x - t * 3, size.y - t * 3.5),
					size: Vector2.new(t * 3, t * 3),
					angle: toRadians(45),
				},
			],
			spawns: [
				Vector2.new(50, 50),
				Vector2.new(size.x - 50, size.y - 50),
				Vector2.new(50, size.y - 50),
				Vector2.new(size.x - 50, 50),
			],
		};
	})(Vector2.new(800, 600)),
	{
		name: "Basic",
		size: Vector2.new(600, 600),
		objects: [
			{
				type: "rectangle",
				pos: { x: 300, y: 300 },
				size: { x: 200, y: 200 },
				angle: toRadians(45),
			},
			{
				type: "rectangle",
				pos: { x: 75, y: 75 },
				size: { x: 150, y: 150 },
			},
			{
				type: "rectangle",
				pos: { x: 75, y: 525 },
				size: { x: 150, y: 150 },
			},
			{
				type: "rectangle",
				pos: { x: 525, y: 75 },
				size: { x: 150, y: 150 },
			},
			{
				type: "rectangle",
				pos: { x: 525, y: 525 },
				size: { x: 150, y: 150 },
			},
		],
		spawns: [Vector2.new(50, 300), Vector2.new(550, 300), Vector2.new(300, 50), Vector2.new(300, 550)],
	},
	((size: Vector2) => {
		const t = (size.x * (2 - Math.sqrt(2))) / 2;
		return {
			name: "Octagon",
			size,
			objects: [
				{
					type: "triangle",
					pos: { x: t / 2, y: size.y - t / 2 },
					size: { x: t, y: t },
				},
				{
					type: "triangle",
					pos: Vector2.new(size.x - t / 2, size.y - t / 2),
					size: Vector2.new(t, t),
					angle: toRadians(-90),
				},
				{
					type: "triangle",
					pos: Vector2.new(size.x - t / 2, t / 2),
					size: Vector2.new(t, t),
					angle: toRadians(-180),
				},
				{
					type: "triangle",
					pos: Vector2.new(t / 2, t / 2),
					size: Vector2.new(t, t),
					angle: toRadians(-270),
				},
			],
			spawns: [
				Vector2.new(50, size.y / 2),
				Vector2.new(size.x - 50, size.y / 2),
				Vector2.new(size.x / 2, 50),
				Vector2.new(size.x / 2, size.y - 50),
			],
		};
	})(Vector2.new(600, 600)),
	((size: Vector2) => {
		const t = (100 * 8) / 6;
		return {
			name: "Chaos",
			size,
			objects: [
				{
					type: "circle",
					pos: { x: size.x / 2, y: 0 },
					radius: t,
				},
				{
					type: "circle",
					pos: { x: size.x / 2, y: size.y },
					radius: t,
				},
				{
					type: "circle",
					pos: { x: 0, y: size.y / 2 },
					radius: t,
				},
				{
					type: "circle",
					pos: { x: size.x, y: size.y / 2 },
					radius: t,
				},
				{
					type: "circle",
					pos: { x: size.x / 2 - t * 2, y: size.y / 2 - t * 2 },
					radius: t / 2,
				},
				{
					type: "circle",
					pos: { x: size.x / 2 - t * 2, y: size.y / 2 + t * 2 },
					radius: t / 2,
				},
				{
					type: "circle",
					pos: { x: size.x / 2 + t * 2, y: size.y / 2 - t * 2 },
					radius: t / 2,
				},
				{
					type: "circle",
					pos: { x: size.x / 2 + t * 2, y: size.y / 2 + t * 2 },
					radius: t / 2,
				},
				{
					type: "circle",
					pos: { x: size.x / 2 - t * 0.75, y: size.y / 2 - t * 0.75 },
					radius: t / 2,
				},
				{
					type: "circle",
					pos: { x: size.x / 2 - t * 0.75, y: size.y / 2 + t * 0.75 },
					radius: t / 2,
				},
				{
					type: "circle",
					pos: { x: size.x / 2 + t * 0.75, y: size.y / 2 - t * 0.75 },
					radius: t / 2,
				},
				{
					type: "circle",
					pos: { x: size.x / 2 + t * 0.75, y: size.y / 2 + t * 0.75 },
					radius: t / 2,
				},
			],
			spawns: [
				Vector2.new(50, 50),
				Vector2.new(size.x - 50, size.y - 50),
				Vector2.new(50, size.y - 50),
				Vector2.new(size.x - 50, 50),
			],
		};
	})(Vector2.new(800, 800)),
] as const;

export const TEAM_COLORS = [
	"#0074D9", // Blue
	"#2ECC40", // Green
	"#FFDC00", // Yellow
	"#B10DC9", // Purple
	"#FF851B", // Orange
	"#7FDBFF", // Cyan
	"#F012BE", // Pink
	"#39CCCC", // Teal
] as const;

export const SETTINGS_ROWS: ModifiableSettingsOptions = {
	stageIndex: {
		label: "Stage",
		values: STAGES.map((_, i) => i),
		valueLabels: STAGES.map(({ name }) => name),
		defaultIndex: 0,
	},
	projectileSpeed: {
		label: "Projectile Speed",
		values: [100, 150, 200, 250, 300, 350, 400, 450, 500],
		defaultIndex: 2,
	},
	playerSpeed: {
		label: "Player Speed",
		values: [60, 80, 100, 120, 140, 160, 180, 200, 220],
		defaultIndex: 3,
	},
	maxBounces: {
		label: "Max Bounces",
		values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
		defaultIndex: 3,
	},
	lives: {
		label: "Lives",
		values: [1, 2, 3, 4, 5, 6, 7, 8],
		defaultIndex: 2,
	},
};
