import React from "react";
import { MainMenu } from "../Components/Battleship/MainMenu";
import { Battleship } from "../Components/Battleship/Battleship";
import { Helmet } from "react-helmet";
import "../Components/App.css";

export const BattleshipIndex: React.FC = () => {
	const [socket, setSocket] = React.useState<WebSocket | undefined>();
	return (
		<>
			<Helmet>
				<title>Play Battleship Online</title>
				<meta
					name="description"
					content="Play real-time multiplayer Battleship online. Challenge friends or opponents worldwide. Sink enemy ships to win!"
				/>
				<link rel="canonical" href="/battleship" />
				<meta property="og:title" content="Play Battleship Online" />
				<meta property="og:description" content="Play against other players in real-time" />
				<meta property="og:url" content="/battleship" />
			</Helmet>
			{socket === undefined ? <MainMenu setSocket={setSocket} /> : <Battleship ws={socket} />}
		</>
	);
};

export default BattleshipIndex;
