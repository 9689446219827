import React from "react";
import styles from "./Attack.module.css";
import { serializePoint } from "../../../Shared/battleship/BattleshipSharedTypes";
import { Point } from "../../../Shared/math/Vector";

function patternToGrid(pattern: Point[]): boolean[][] {
	const cache = new Map<string, boolean>();
	let { x: minX, x: maxX, y: minY, y: maxY } = pattern[0];
	for (const point of pattern) {
		const { x, y } = point;
		minX = Math.min(minX, x);
		maxX = Math.max(maxX, x);
		minY = Math.min(minY, y);
		maxY = Math.max(maxY, y);
		cache.set(serializePoint(point), true);
	}
	const spanX = maxX - minX + 1;
	const spanY = maxY - minY + 1;
	const grid = Array.from({ length: spanY }, (_, y) =>
		Array.from({ length: spanX }, (_, x) => cache.has(serializePoint({ x: x + minX, y: y + minY }))),
	);
	return grid;
}

function sizeToGrid(size: Point): boolean[][] {
	const { x, y } = size;
	return Array.from({ length: y }, () => Array.from({ length: x }, () => true));
}

interface GridPatternProps {
	size?: Point;
	pattern?: Point[];
	color?: string;
}

export const GridPatternFC: React.FC<GridPatternProps> = ({ size, pattern, color = "#ff4d4d" }) => {
	const grid = (size && sizeToGrid(size)) || (pattern && patternToGrid(pattern));
	if (!grid) return <div />;
	return (
		<div className={styles["grid-container"]}>
			{grid.map((row, rowIndex) => (
				<div className={styles["grid-row"]} key={rowIndex}>
					{row.map((bool, colIndex) => (
						<div
							className={bool ? styles["filled-cell"] : styles["empty-cell"]}
							style={bool ? { backgroundColor: color } : {}}
							key={`${rowIndex}-${colIndex}`}
						/>
					))}
				</div>
			))}
		</div>
	);
};

export const GridPattern = React.memo(GridPatternFC);
