export function shortestSignedAngle(angle1: number, angle2: number): number {
	const diff = (angle2 % (2 * Math.PI)) - (angle1 % (2 * Math.PI));
	if (diff > Math.PI) {
		return diff - 2 * Math.PI;
	} else if (diff < -Math.PI) {
		return diff + 2 * Math.PI;
	}
	return diff;
}

export function toRadians(deg: number): number {
	return (deg / 180) * Math.PI;
}

export function toDegrees(rad: number): number {
	return (rad / Math.PI) * 180;
}
