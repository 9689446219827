import Box2D from "box2dweb";
import { InstanceHandler, WithInstanceProps } from "./InstanceHandler";
import { CreateBody } from "./Box2DHelpers";
import { Render } from "./RenderHelpers";
import { Vector2 } from "../math/Vector";

export type ObjectDescriptor =
	| {
			type: "rectangle";
			pos: Vector2;
			size: Vector2;
			angle?: number;
	  }
	| {
			type: "circle";
			pos: Vector2;
			radius: number;
	  }
	| {
			type: "triangle";
			pos: Vector2;
			size: Vector2;
			angle?: number;
	  };

export type ObjectInstance = WithInstanceProps<{
	type: "wall";
	desc: Required<ObjectDescriptor>;
}>;

export class ObjectHandler extends InstanceHandler<ObjectDescriptor, ObjectInstance> {
	public static resizeDescriptors(descriptors: ObjectDescriptor[], pixelsPerUnit: number): ObjectDescriptor[] {
		return descriptors.map((desc) => {
			switch (desc.type) {
				case "rectangle":
					return {
						type: "rectangle",
						pos: Vector2.mul(desc.pos, 1 / pixelsPerUnit),
						size: Vector2.mul(desc.size, 1 / pixelsPerUnit),
						angle: desc.angle,
					};
				case "circle":
					return {
						type: "circle",
						pos: Vector2.mul(desc.pos, 1 / pixelsPerUnit),
						radius: desc.radius / pixelsPerUnit,
					};
				case "triangle":
					return {
						type: "triangle",
						pos: Vector2.mul(desc.pos, 1 / pixelsPerUnit),
						size: Vector2.mul(desc.size, 1 / pixelsPerUnit),
						angle: desc.angle,
					};
			}
		});
	}

	public static makeWallDescriptors(size: Vector2): ObjectDescriptor[] {
		return [
			{
				type: "rectangle",
				pos: Vector2.new(-50, size.y / 2),
				size: Vector2.new(100, size.y),
			},
			{
				type: "rectangle",
				pos: Vector2.new(size.x + 50, size.y / 2),
				size: Vector2.new(100, size.y),
			},
			{
				type: "rectangle",
				pos: Vector2.new(size.x / 2, -50),
				size: Vector2.new(size.x + 100, 100),
			},
			{
				type: "rectangle",
				pos: Vector2.new(size.x / 2, size.y + 50),
				size: Vector2.new(size.x + 100, 100),
			},
		];
	}

	constructor(world: Box2D.Dynamics.b2World) {
		super(
			world,
			(props) => {
				return {
					type: "wall",
					desc: {
						...props,
						angle: props.angle ?? 0,
					},
					body: CreateBody.StaticObject(world, props),
					dead: false,
				};
			},
			(object) => object.desc,
		);
	}

	public render(context: CanvasRenderingContext2D, pixelsPerUnit: number) {
		for (const [, object] of this.iterator()) {
			Render.Object(context, object, pixelsPerUnit);
		}
	}
}
