import React from "react";
import { SocketClient } from "../Shared/socket/SocketClient";
import { ClientDefinition, ServerDefinition, PlayerInfo, LobbyStateSettings } from "../Shared/lobby/LobbySharedTypes";
import { GenerateReducerMessages, generateReducerHandlers } from "../Shared/socket/SocketTypes";
import { useImmerReducer } from "use-immer";
import { StateManager } from "../Shared/StateManager";
import { useWebsocket } from "./useWebsocket";
import { ModifiableSettingsSelections, getDefaultSelections } from "../Shared/game/RicochetGameTypes";
import { SETTINGS_ROWS } from "../Shared/game/Data";

export type LobbyState = {
	settingsSelection: ModifiableSettingsSelections;
	players: PlayerInfo[];
	settings: LobbyStateSettings;
	idPlayer: string;
};

const newState: () => LobbyState = () => ({
	settingsSelection: getDefaultSelections(SETTINGS_ROWS),
	players: [],
	settings: { maxPlayers: 0 },
	idPlayer: "",
});

export type BattleshipDispatch = React.Dispatch<ClientDefinition>;

export type Socket = SocketClient<ClientDefinition, ServerDefinition>;

type LobbyReducer = (state: LobbyState, action: GenerateReducerMessages<ClientDefinition>) => LobbyState;

const reducer: LobbyReducer = (state, { type, payload }) => {
	switch (type) {
		case "initialize":
			state.idPlayer = payload.id;
			state.settings = payload.settings;
			state.settingsSelection = payload.settingsSelection;
			break;
		case "player_list":
			state.players = payload;
			break;
		case "player_update": {
			const player = state.players.find((p) => p.id === payload.id);
			if (player) {
				StateManager.applyDelta(player.state, payload.delta);
			}
			break;
		}
		case "settings_selection":
			state.settingsSelection = payload;
			break;
		case "announcement":
			break;
	}
	return state;
};

export function useLobby(ws: WebSocket, setTransfer: React.Dispatch<React.SetStateAction<boolean>>) {
	const [state, dispatch] = useImmerReducer(reducer, undefined, newState);

	const socket = React.useMemo(
		() =>
			new SocketClient<ClientDefinition, ServerDefinition>(ws, {
				...generateReducerHandlers(
					["initialize", "player_list", "player_update", "announcement", "transfer", "settings_selection"],
					dispatch,
				),
				transfer: () => {
					setTransfer(true);
				},
				ping: (payload) => payload,
			}),
		[dispatch, ws, setTransfer],
	);

	const onSelect = React.useCallback(
		(rowIndex: number, optionIndex: number) => {
			const newSelection = [...state.settingsSelection];
			newSelection[rowIndex] = optionIndex;
			socket.send("settings_selection", newSelection);
		},
		[socket, state.settingsSelection],
	);

	useWebsocket(ws, socket, false);

	return { state, dispatch, socket, onSelect };
}
