import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BattleshipPage from "../Pages/BattleshipPage";
import HomePage from "../Pages/HomePage";
import NotFoundPage from "../Pages/NotFoundPage";
import RicochetPage from "../Pages/RicochetPage";

function App() {
	return (
		<>
			<BrowserRouter>
				<div className="App-header">
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/battleship" element={<BattleshipPage />} />
						<Route path="/ricochet" element={<RicochetPage />} />
						<Route path="/*" element={<NotFoundPage />} />
					</Routes>
				</div>
			</BrowserRouter>
		</>
	);
}

export default App;

/*
const BattleshipIndex = lazy(() => import("./Battleship"));
const Homepage = lazy(() => import("./Homepage"));
const NotFound = lazy(() => import("./NotFound"));
*/
