import React from "react";
import styles from "./Board.module.css";
import IconMiss from "../../../svgs/xmark.svg";
import IconHit from "../../../svgs/crosshairs.svg";

export enum TileType {
	Empty,
	Hit,
	Miss,
}

interface TileProps {
	x: number;
	y: number;
	type: TileType;
	color?: string;
	selected: boolean;
	onHover?: (x: number, y: number) => void;
	onClick?: (x: number, y: number) => void;
}

const ICONS = {
	[TileType.Empty]: undefined,
	[TileType.Hit]: IconHit,
	[TileType.Miss]: IconMiss,
};

const TileFC: React.FC<TileProps> = ({ x, y, type, color = "white", selected, onHover, onClick }) => {
	const icon = ICONS[type];
	let backgroundColor = selected ? "#ff4d4d" : color;
	if (type === TileType.Hit && backgroundColor === "white") {
		backgroundColor = "green";
	}
	return (
		<div
			className={styles["grid-cell"]}
			style={{ backgroundColor }}
			onClick={onClick && (() => onClick(x, y))}
			onMouseEnter={onHover && (() => onHover(x, y))}
		>
			{icon && <img className={styles["cell-icon"]} src={icon} />}
		</div>
	);
};

export const Tile = React.memo(TileFC);
