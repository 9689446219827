import React from "react";
import styles from "./Attack.module.css";
import { PatternButton } from "./PatternButton";
import { PlacingShips } from "../Types";

interface ShipSelectProps {
	ships: PlacingShips;
	indexSelected: number;
	onSelect?: (index: number) => void;
}

export const ShipSelect: React.FC<ShipSelectProps> = ({ ships, indexSelected, onSelect }) => {
	const attemptSelect = React.useCallback(
		(index: number) => {
			if (index === indexSelected) return;
			if (onSelect === undefined) return;
			onSelect(index);
		},
		[indexSelected, onSelect],
	);

	return (
		<div className={styles["attack-option-container"]}>
			{ships.map((ship, index) => (
				<PatternButton
					key={index}
					size={ship.descriptor.size}
					title={ship.descriptor.name}
					patternColor={ship.descriptor.color}
					selected={index === indexSelected}
					highlighted={ship.placement !== undefined}
					index={index}
					onClick={attemptSelect}
				/>
			))}
		</div>
	);
};
