import { StageData, TEAM_COLORS } from "./Data";
import { InstanceId } from "./InstanceHandler";
import { ObjectDescriptor, ObjectInstance } from "./ObjectHandler";
import { PlayerHandler, PlayerInstance } from "./PlayerHandler";
import { ProjectileInstance } from "./ProjectileHandler";

function drawBullet(
	context: CanvasRenderingContext2D,
	x: number,
	y: number,
	radius: number,
	length: number,
	rot: number,
	fillStyle: string = "red",
) {
	context.save();
	context.fillStyle = fillStyle;
	context.translate(x, y);
	context.rotate(rot);
	context.beginPath();
	context.arc(0, 0, radius, Math.PI / 2, -Math.PI / 2, true);
	context.lineTo(-length, -radius);
	context.lineTo(-length, radius);
	context.lineTo(0, radius);
	context.closePath();
	context.fill();
	context.stroke();
	context.restore();
}

function drawObject(
	context: CanvasRenderingContext2D,
	x: number,
	y: number,
	rot: number,
	desc: ObjectDescriptor,
	pixelsPerUnit: number,
) {
	context.fillStyle = "grey";
	switch (desc.type) {
		case "rectangle": {
			context.save();
			context.translate(x, y);
			if (rot !== 0) {
				context.rotate(rot);
			}
			context.fillRect(
				-(desc.size.x / 2) * pixelsPerUnit,
				-(desc.size.y / 2) * pixelsPerUnit,
				desc.size.x * pixelsPerUnit,
				desc.size.y * pixelsPerUnit,
			);
			context.restore();
			return;
		}
		case "circle":
			context.beginPath();
			context.arc(x, y, desc.radius * pixelsPerUnit, 0, 2 * Math.PI);
			context.closePath();
			context.fill();
			return;
		case "triangle": {
			context.save();
			context.translate(x, y);
			if (rot !== 0) {
				context.rotate(rot);
			}
			context.beginPath();
			context.lineTo((-desc.size.x / 2) * pixelsPerUnit, (-desc.size.y / 2) * pixelsPerUnit);
			context.lineTo((-desc.size.x / 2) * pixelsPerUnit, (desc.size.y / 2) * pixelsPerUnit);
			context.lineTo((desc.size.x / 2) * pixelsPerUnit, (desc.size.y / 2) * pixelsPerUnit);
			context.closePath();
			context.fill();
			context.restore();
			return;
		}
	}
}

const drawHeart = (() => {
	const z = 10;
	const q = 18;
	const r = 30;
	const d = 100 - 2 * r;
	const a = (1 / d) * Math.sqrt(4 * d * d * r * r - d * d * d * d);
	const h = Math.asin(a / (2 * r));
	return (context: CanvasRenderingContext2D, x: number, y: number, width: number, height: number) => {
		context.save();
		context.translate(x, y);
		context.scale(width / 100, height / 100);
		context.beginPath();
		context.moveTo(0, 50);
		context.quadraticCurveTo(-25 - q - z, r / 2 - q + z, -50, r - 50);
		context.arc(r - 50, r - 50, r, Math.PI, 2 * Math.PI - h);
		context.arc(50 - r, r - 50, r, Math.PI + h, 2 * Math.PI);
		context.moveTo(50, r - 50);
		context.quadraticCurveTo(25 + q + z, r / 2 - q + z, 0, 50);
		context.restore();
	};
})();

export const Render = {
	Projectile(
		context: CanvasRenderingContext2D,
		proj: ProjectileInstance,
		pixelsPerUnit: number,
		maxBounces: number,
		radius: number,
		length: number = 10 * (radius / 4),
	) {
		const pos = proj.body.GetPosition();
		drawBullet(
			context,
			pos.x * pixelsPerUnit,
			pos.y * pixelsPerUnit,
			radius,
			length,
			proj.angle,
			`hsl(0 100% ${50 + (proj.bounces / (maxBounces > 0 ? maxBounces : 1)) * 40}%)`,
		);
	},
	Object(context: CanvasRenderingContext2D, object: ObjectInstance, pixelsPerUnit: number) {
		const pos = object.body.GetPosition();
		const x = pos.x * pixelsPerUnit;
		const y = pos.y * pixelsPerUnit;
		drawObject(context, x, y, object.body.GetAngle(), object.desc, pixelsPerUnit);
	},
	Player(
		context: CanvasRenderingContext2D,
		player: PlayerInstance,
		pixelsPerUnit: number,
		highlightPlayer?: InstanceId,
	) {
		const pos = player.body.GetPosition();
		const x = pos.x * pixelsPerUnit;
		const y = pos.y * pixelsPerUnit;
		const w = 24;
		const t = 6;
		if (!PlayerHandler.isAlive(player)) {
			context.save();
			context.translate(x, y);
			context.rotate(Math.PI / 4);
			context.fillStyle = TEAM_COLORS[player.team % TEAM_COLORS.length];
			context.fillRect(-w / 2, -t / 2, w, t);
			context.fillRect(-t / 2, -w / 2, t, w);
			context.restore();
			return;
		}
		const out = 8;
		const alpha = player.iframes <= 0 ? 1 : 0.5 + 0.25 * Math.cos((player.iframes / 120) * 12 * Math.PI);
		context.save();
		if (highlightPlayer === player.id) {
			context.strokeStyle = "white";
		} else {
			context.strokeStyle = "black";
		}
		context.globalAlpha = alpha;
		context.fillStyle = TEAM_COLORS[player.team % TEAM_COLORS.length];
		context.translate(x, y);
		context.rotate(player.moveAngle);
		context.fillRect(0 - w / 2, -out - t / 2, w, t);
		context.fillRect(0 - w / 2, out - t / 2, w, t);
		context.globalAlpha = 1;
		context.strokeRect(0 - w / 2, -out - t / 2, w, t);
		context.strokeRect(0 - w / 2, out - t / 2, w, t);
		context.globalAlpha = alpha;
		context.beginPath();
		context.arc(0, 0, 9, 0, 2 * Math.PI);
		context.closePath();
		context.fill();
		context.globalAlpha = 1;
		context.stroke();
		context.rotate(player.angle - player.moveAngle);
		context.globalAlpha = alpha;
		context.fillRect(0, 0 - 4 / 2, 18, 4);
		context.globalAlpha = 1;
		context.strokeRect(0, 0 - 4 / 2, 18, 4);
		/*	context.beginPath();
        context.arc(0, 0, 10, 0, 2 * Math.PI);
        context.closePath();
        context.stroke();*/
		context.restore();
	},
	Stage(context: CanvasRenderingContext2D, stage: StageData, pixelsPerUnit: number) {
		for (const object of stage.objects) {
			const angle = object.type === "circle" ? 0 : object.angle ?? 0;
			drawObject(context, object.pos.x, object.pos.y, angle, object, pixelsPerUnit);
		}
		for (const [i, { x, y }] of stage.spawns.entries()) {
			context.fillStyle = TEAM_COLORS[i % TEAM_COLORS.length];
			context.fillRect(x * pixelsPerUnit - 8, y * pixelsPerUnit - 8, 16, 16);
		}
	},
	Heart(
		context: CanvasRenderingContext2D,
		x: number,
		y: number,
		width: number,
		height: number,
		filled: boolean = true,
	) {
		drawHeart(context, x, y, width, height);
		if (filled) {
			context.fillStyle = "red";
		} else {
			context.fillStyle = "rgba(0, 0, 0, 0.5)";
		}
		context.fill();
		context.stroke();
	},
	Health(context: CanvasRenderingContext2D, x: number, y: number, hp: number, maxHp: number) {
		for (let i = 0; i < maxHp; i++) {
			this.Heart(context, x + i * 24, y, 20, 20, i < hp);
		}
	},
};
