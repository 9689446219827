import React from "react";

const SOCKET_PROTOCOL = window.location.protocol === "https:" ? "wss:" : "ws:";
const HOSTNAME = window.location.hostname;
const PORT = window.location.port ? `:${window.location.port}` : "";

const SERVER_HTTP = process.env.REACT_APP_SERVER || "";
const SERVER_WS = process.env.REACT_APP_SERVER_WS || `${SOCKET_PROTOCOL}//${HOSTNAME}${PORT}`;

export type SessionProps = {
	setSocket: React.Dispatch<React.SetStateAction<WebSocket | undefined>>;
	sessionType: string;
};

export function useSession({ setSocket, sessionType }: SessionProps) {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState("");

	const joinSession = React.useCallback(
		async (idSession: string) => {
			try {
				const socket = new WebSocket(`${SERVER_WS}/join/${sessionType}/${idSession}`);
				console.log("Successfully joined ", idSession);
				setSocket(socket);
			} catch (error) {
				console.error("Error joining session", error);
			}
		},
		[sessionType, setSocket],
	);

	const createNewSession = React.useCallback(
		async (isPublic: boolean) => {
			try {
				const response = await fetch(`${SERVER_HTTP}/create/${sessionType}`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ public: isPublic }),
				});
				const jsonData = await response.json();
				if (jsonData.error) {
					setError(jsonData.error);
					return;
				}
				if (!jsonData.idSession) {
					setError("Error creating session");
					return;
				}
				await joinSession(jsonData.idSession);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		},
		[joinSession, sessionType],
	);

	const findPublicSession = React.useCallback(async () => {
		try {
			const response = await fetch(`${SERVER_HTTP}/find/${sessionType}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			const jsonData = await response.json();
			if (jsonData.error) {
				setError(jsonData.error);
				return;
			}
			if (jsonData.idSession) {
				await joinSession(jsonData.idSession);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}, [joinSession, sessionType]);

	const attemptJoinSession = React.useCallback(
		async (idSession?: string) => {
			if (loading) return;
			setLoading(true);
			setError("");
			if (!idSession) {
				await findPublicSession();
			} else {
				await joinSession(idSession);
			}
			setLoading(false);
		},
		[findPublicSession, joinSession, loading],
	);

	const attemptCreateSession = React.useCallback(
		async (isPublic: boolean) => {
			if (loading) return;
			setLoading(true);
			setError("");
			await createNewSession(isPublic);
			setLoading(false);
		},
		[createNewSession, loading],
	);

	return { loading, error, attemptJoinSession, attemptCreateSession };
}
