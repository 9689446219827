import React, { CSSProperties } from "react";
import styles from "./Shared.module.css";
import { classNames } from "../../Util/Classnames";

type RoundedButtonProps = {
	onClick?: () => void;
	disabled?: boolean;
	style?: CSSProperties;
	children?: React.ReactNode;
};

export const RoundedButton: React.FC<RoundedButtonProps> = ({ onClick, disabled = false, style, children }) => {
	return (
		<button
			className={classNames({
				[styles.rounded_button]: true,
				[styles.disabled]: disabled,
			})}
			onClick={!disabled ? onClick : undefined}
			style={style}
		>
			{children}
		</button>
	);
};
