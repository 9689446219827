import React from "react";
import { PointMap } from "../Components/Battleship/Types";
import {
	AttackInstance,
	ClientDefinition,
	GameSettings,
	GameState,
	ServerDefinition,
	ShipPlacement,
	serializePoint,
} from "../Shared/battleship/BattleshipSharedTypes";
import { SocketClient } from "../Shared/socket/SocketClient";
import { CommunicationDefinition } from "../Shared/socket/SocketTypes";
import { Point } from "../Shared/math/Vector";

type ClientGameState = "WaitingForSettings" | GameState;

export type BattleshipGameState = {
	gameState: ClientGameState;
	gameSettings: GameSettings;
	attackInstances: AttackInstance[];
	isPlayerTurn: boolean;
	didPlayerWin: boolean;
	player: {
		hitMap: PointMap;
		selectedTiles: Set<string>;
		placingShip?: ShipPlacement;
		ships: ShipPlacement[];
		shipsConfirmed: boolean;
	};
	opponent: {
		hitMap: PointMap;
		selectedTiles: Set<string>;
		ships: ShipPlacement[];
		shipsConfirmed: boolean;
	};
};

const newGameState: BattleshipGameState = {
	gameState: "WaitingForSettings",
	gameSettings: { boardSize: { x: 10, y: 10 }, shipList: [], attackList: [] },
	attackInstances: [],
	isPlayerTurn: false,
	didPlayerWin: false,
	player: { hitMap: new Map(), selectedTiles: new Set(), ships: [], shipsConfirmed: false },
	opponent: { hitMap: new Map(), selectedTiles: new Set(), ships: [], shipsConfirmed: false },
};

type ClientMessages<T extends CommunicationDefinition> = {
	[K in keyof T]: T[K] extends { payload: undefined }
		? {
				type: K;
			}
		: {
				type: K;
			} & T[K]["payload"];
}[keyof T];

type DispatchMessage =
	| ClientMessages<ClientDefinition>
	| {
			type: "set_selected";
			target: "player" | "opponent";
			tiles: Point[];
	  }
	| {
			type: "set_ships";
			placements: ShipPlacement[];
			confirmed: boolean;
	  }
	| {
			type: "use_attack";
			index: number;
	  };

export type BattleshipDispatch = React.Dispatch<DispatchMessage>;

export type Socket = SocketClient<ClientDefinition, ServerDefinition>;

type BattleshipReducer = (state: BattleshipGameState, action: DispatchMessage) => BattleshipGameState;

const applyChanges = <S, K extends keyof S>(state: S, changes: Pick<S, K>): S => Object.assign({}, state, changes);

const reducer: BattleshipReducer = (state, action) => {
	switch (action.type) {
		case "settings":
			if (state.gameState !== "WaitingForSettings") {
				console.error("Received game settings when not in waiting for settings state");
				return state;
			}
			return applyChanges(state, {
				gameSettings: action.settings,
				attackInstances: action.settings.attackList.map((attack) => ({
					limited: attack.max > 0,
					count: attack.max,
					option: attack,
				})),
			});
		case "game_state":
			if (state.gameState === action.state) {
				console.error("Received duplicate state", action.state);
				return state;
			}
			return applyChanges(state, { gameState: action.state });
		case "your_turn":
			if (state.isPlayerTurn) {
				console.error("Received your_turn when already player's turn");
				return state;
			}
			return applyChanges(state, {
				isPlayerTurn: true,
				opponent: applyChanges(state.opponent, { selectedTiles: new Set<string>() }),
			});
		case "attack_results": {
			const map = new Map(action.enemy ? state.opponent.hitMap : state.player.hitMap);
			for (const result of action.results) {
				map.set(serializePoint(result.point), result.hit);
			}
			if (action.enemy) {
				const key = "opponent";
				return applyChanges(state, { [key]: applyChanges(state[key], { hitMap: map }) });
			}
			const key = "player";
			return applyChanges(state, { [key]: applyChanges(state[key], { hitMap: map }) });
		}
		case "game_over":
			return applyChanges(state, {
				didPlayerWin: action.winner,
				isPlayerTurn: false,
				opponent: applyChanges(state.opponent, { ships: action.enemyPlacements }),
			});
		case "set_selected": {
			if (action.target === "player") {
				const key = "player";
				return applyChanges(state, {
					[key]: applyChanges(state[key], {
						selectedTiles: new Set<string>(action.tiles.map(serializePoint)),
					}),
				});
			}
			const key = "opponent";
			return applyChanges(state, {
				[key]: applyChanges(state[key], {
					selectedTiles: new Set<string>(action.tiles.map(serializePoint)),
				}),
			});
		}
		case "set_ships":
			return applyChanges(state, {
				player: applyChanges(state.player, { ships: action.placements, shipsConfirmed: action.confirmed }),
			});
		case "confirm_board": {
			if (action.enemy) {
				const key = "opponent";
				return applyChanges(state, {
					[key]: applyChanges(state[key], { shipsConfirmed: action.confirmed }),
				});
			}
			const key = "player";
			return applyChanges(state, {
				[key]: applyChanges(state[key], { shipsConfirmed: action.confirmed }),
			});
		}
		case "hover_attack":
			return applyChanges(state, {
				opponent: applyChanges(state.opponent, { selectedTiles: new Set<string>(action.points.map(serializePoint)) }),
			});
		case "use_attack": {
			const attack = state.attackInstances[action.index];
			if (attack.count <= 0 && attack.limited) {
				console.error("Tried to use empty attack");
				return state;
			}
			return applyChanges(state, {
				isPlayerTurn: false,
				attackInstances: state.attackInstances.map((attack, index) =>
					index === action.index ? applyChanges(attack, { count: attack.count - 1 }) : attack,
				),
			});
		}
	}
};

export function useGame(ws: WebSocket) {
	const [state, dispatch] = React.useReducer<BattleshipReducer>(reducer, newGameState);

	const socket = React.useMemo(
		() =>
			new SocketClient<ClientDefinition, ServerDefinition>(ws, {
				["game_state"]: (payload) => dispatch({ type: "game_state", ...payload }),
				["attack_results"]: (payload) => dispatch({ type: "attack_results", ...payload }),
				["game_over"]: (payload) => dispatch({ type: "game_over", ...payload }),
				["confirm_board"]: (payload) => dispatch({ type: "confirm_board", ...payload }),
				["settings"]: (payload) => dispatch({ type: "settings", ...payload }),
				["hover_attack"]: (payload) => dispatch({ type: "hover_attack", ...payload }),
				["your_turn"]: () => dispatch({ type: "your_turn" }),
			}),
		[ws, dispatch],
	);

	React.useEffect(() => {
		console.log("Using socket!");
		ws.addEventListener("open", () => {
			console.log("Connected to WebSocket server");
		});

		ws.addEventListener("message", (message) => socket.receiveMessage(message.data));

		ws.addEventListener("error", (error) => {
			console.error("WebSocket error:", error);
		});

		return () => {
			console.log("CLOSING SOCKET");
			ws.close();
		};
	}, [socket, ws]);

	return { state, dispatch, socket };
}
