import React from "react";
import "../App.css";
import "./Ricochet.module.css";
import { CanvasCallback } from "../../Shared/game/RicochetGameClient";

interface CanvasProps {
	width: number;
	height: number;
	callback: CanvasCallback;
}

export const PersistentCanvas: React.FC<CanvasProps> = ({ width, height, callback }) => {
	const canvasRef = React.useRef<HTMLCanvasElement>(null);

	React.useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const context = canvas.getContext("2d");
		if (!context) return;

		/*function captureFocus(event: MouseEvent) {
			if (!canvas) return;
			event.preventDefault();
			event.stopPropagation();
			canvas.focus();
		}
		document.addEventListener("click", captureFocus);*/
		callback.connect(canvas, context);

		return () => {
			// document.removeEventListener("click", captureFocus);
			callback.disconnect();
		};
	}, [callback]);

	return <canvas ref={canvasRef} tabIndex={0} width={width} height={height} />;
};
