export type MutableVector2 = {
	x: number;
	y: number;
};

export type Vector2 = Readonly<MutableVector2>;

export type Point = Vector2;

type Vector2Global = {
	ZERO: Vector2;
	Y_AXIS: Vector2;
	X_AXIS: Vector2;
	angle: (a: Vector2) => number;
	dot: (a: Vector2, b: Vector2) => number;
	length: (a: Vector2) => number;
	length2: (a: Vector2) => number;
	angleBetween: (a: Vector2, b: Vector2) => number;
	angleBetweenSigned: (a: Vector2, b: Vector2) => number;
	isZero: (a: Vector2) => boolean;
	distance: (a: Vector2, b: Vector2) => number;
	fromAngle: (angle: number) => Vector2;
	new: (x: number, y: number) => Vector2;
};

type Vector2Methods<Vector2> = {
	cross: (a: Vector2, b: Vector2) => Vector2;
	add: (a: Vector2, b: Vector2) => Vector2;
	sub: (a: Vector2, b: Vector2) => Vector2;
	mul: (a: Vector2, k: number) => Vector2;
	unit: (a: Vector2) => Vector2;
	toLength: (a: Vector2, length: number) => Vector2;
	toMaxLength: (a: Vector2, maxLength: number) => Vector2;
	dir: (a?: Vector2) => Vector2;
	reflect: (a: Vector2, n: Vector2) => Vector2;
};

export const Vector2: Vector2Global & Vector2Methods<Vector2> = {
	ZERO: { x: 0, y: 0 },
	Y_AXIS: { x: 0, y: 1 },
	X_AXIS: { x: 1, y: 0 },
	angle: (a: Vector2) => Math.atan2(a.y, a.x),
	cross: (a: Vector2, b: Vector2) => ({ x: a.y * b.x - a.x * b.y, y: a.x * b.y - a.y * b.x }),
	dot: (a: Vector2, b: Vector2) => a.x * b.x + a.y * b.y,
	add: (a: Vector2, b: Vector2) => ({ x: a.x + b.x, y: a.y + b.y }),
	sub: (a: Vector2, b: Vector2) => ({ x: a.x - b.x, y: a.y - b.y }),
	mul: (a: Vector2, k: number) => ({ x: a.x * k, y: a.y * k }),
	length: (a: Vector2) => Math.sqrt(a.x * a.x + a.y * a.y),
	length2: (a: Vector2) => a.x * a.x + a.y * a.y,
	angleBetween: (a: Vector2, b: Vector2) => Math.acos(Vector2.dot(a, b) / (Vector2.length(a) * Vector2.length(b))),
	angleBetweenSigned: (a: Vector2, b: Vector2) => Math.atan2(a.x * b.y - a.y * b.x, a.x * b.x + a.y * b.y),
	unit: (a: Vector2) => Vector2.mul(a, 1 / Vector2.length(a)),
	toLength: (a: Vector2, length: number) => Vector2.mul(a, length / Vector2.length(a)),
	toMaxLength: (a: Vector2, maxLength: number) => {
		const length = Vector2.length(a);
		return length > maxLength ? Vector2.mul(a, maxLength / length) : a;
	},
	isZero: (a: Vector2) => a.x === 0 && a.y === 0,
	dir: (a?: Vector2) => (a === undefined || Vector2.isZero(a) ? Vector2.X_AXIS : Vector2.unit(a)),
	distance: (a: Vector2, b: Vector2) => {
		const dx = a.x - b.x;
		const dy = a.y - b.y;
		return Math.sqrt(dx * dx + dy * dy);
	},
	reflect: (a: Vector2, n: Vector2) => Vector2.sub(a, Vector2.mul(n, 2 * Vector2.dot(a, n))),
	fromAngle: (angle: number) => ({ x: Math.cos(angle), y: Math.sin(angle) }),
	new: (x: number, y: number) => ({ x, y }),
};

export const MutableVector2: Vector2Methods<MutableVector2> = {
	cross: (a: MutableVector2, b: Vector2) => {
		const x = a.y * b.x - a.x * b.y;
		const y = a.x * b.y - a.y * b.x;
		a.x = x;
		a.y = y;
		return a;
	},
	add: (a: MutableVector2, b: Vector2) => {
		a.x += b.x;
		a.y += b.y;
		return a;
	},
	sub: (a: MutableVector2, b: Vector2) => {
		a.x -= b.x;
		a.y -= b.y;
		return a;
	},
	mul: (a: MutableVector2, k: number) => {
		a.x *= k;
		a.y *= k;
		return a;
	},
	unit: (a: MutableVector2) => {
		const length = Vector2.length(a);
		a.x /= length;
		a.y /= length;
		return a;
	},
	toLength: (a: MutableVector2, length: number) => {
		return MutableVector2.mul(a, length / Vector2.length(a));
	},
	toMaxLength: (a: MutableVector2, maxLength: number) => {
		const length = Vector2.length(a);
		return length > maxLength ? MutableVector2.mul(a, maxLength / length) : a;
	},
	dir: (a?: MutableVector2) => {
		if (a === undefined) {
			return { x: 1, y: 0 };
		}
		if (Vector2.isZero(a)) {
			a.x = 1;
			a.y = 0;
			return a;
		}
		return MutableVector2.unit(a);
	},
	reflect: (a: MutableVector2, n: Vector2) => {
		const dot = Vector2.dot(a, n);
		a.x -= 2 * dot * n.x;
		a.y -= 2 * dot * n.y;
		return a;
	},
};

enum Side {
	LEFT = 0,
	RIGHT = 1,
	BOTTOM = 2,
	TOP = 3,
}

interface Rectangle {
	pos: Vector2;
	width: number;
	height: number;
}

interface IntersectionResult {
	point: Vector2;
	side: Side;
}

function liangBarsky(p1: Vector2, p2: Vector2, rect: Rectangle): IntersectionResult | undefined {
	const dx = p2.x - p1.x;
	const dy = p2.y - p1.y;

	let tMin = 0;
	let tMax = 1;

	let p: number, q: number;
	const xMin = rect.pos.x;
	const xMax = rect.pos.x + rect.width;
	const yMin = rect.pos.y;
	const yMax = rect.pos.y + rect.height;

	p = -dx;
	q = p1.x - xMin;
	if (p === 0 && q < 0) return;

	let t = q / p;
	if (p < 0) {
		if (t > tMin) tMin = t;
	} else if (p > 0) {
		if (t < tMax) tMax = t;
	}

	p = dx;
	q = xMax - p1.x;
	if (p === 0 && q < 0) return;

	t = q / p;
	if (p < 0) {
		if (t > tMin) tMin = t;
	} else if (p > 0) {
		if (t < tMax) tMax = t;
	}

	p = -dy;
	q = p1.y - yMin;
	if (p === 0 && q < 0) return;

	t = q / p;
	if (p < 0) {
		if (t > tMin) tMin = t;
	} else if (p > 0) {
		if (t < tMax) tMax = t;
	}

	p = dy;
	q = yMax - p1.y;
	if (p === 0 && q < 0) return;

	t = q / p;
	if (p < 0) {
		if (t > tMin) tMin = t;
	} else if (p > 0) {
		if (t < tMax) tMax = t;
	}

	if (tMin > tMax) return;

	const intersectionPoint: Vector2 = {
		x: p1.x + tMin * dx,
		y: p1.y + tMin * dy,
	};

	let side = Side.LEFT;
	if (tMin === 0) {
		if (dx > 0) {
			side = Side.RIGHT;
		} else if (dy < 0) {
			side = Side.BOTTOM;
		} else if (dy > 0) {
			side = Side.TOP;
		}
	} else if (tMin === tMax) {
		if (dx < 0) {
			side = Side.RIGHT;
		} else if (dy < 0) {
			side = Side.TOP;
		} else if (dy > 0) {
			side = Side.BOTTOM;
		}
	}

	return { point: intersectionPoint, side };
}

export type RaycastResult = {
	point: Vector2;
	distance: number;
	normal: Vector2;
};

const SIDE_NORMALS = {
	[Side.LEFT]: { x: 1, y: 0 },
	[Side.RIGHT]: { x: -1, y: 0 },
	[Side.BOTTOM]: { x: 0, y: 1 },
	[Side.TOP]: { x: 0, y: -1 },
};

export function rayRectangleIntersection(start: Vector2, end: Vector2, rect: Rectangle): RaycastResult | undefined {
	const intersection = liangBarsky(start, end, rect);
	if (!intersection) return;

	const distance = Vector2.distance(start, intersection.point);
	const normal = SIDE_NORMALS[intersection.side];
	return { point: intersection.point, distance, normal };
}
