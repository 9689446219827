import React from "react";
import "../App.css";
import { HorizontalSpacer } from "../Shared/Spacer";

type PingProps = {
	ping: number;
};

const NUM_BARS = 4;
const WORST_PING = 250;
const GAP = 1;
const colors = ["#FF5733", "#FFC300", "#FFEA00", "#4CAF50"];

export const Ping: React.FC<PingProps> = ({ ping }) => {
	const UNIT = 6;
	const visibleBars = Math.min(
		NUM_BARS - 1,
		Math.max(NUM_BARS - Math.floor((ping / WORST_PING) * (NUM_BARS - 1)) - 1, 0),
	);
	const color = colors[visibleBars];

	return (
		<div>
			<HorizontalSpacer gap={GAP} style={{ alignItems: "flex-end" }}>
				{Array.from({ length: NUM_BARS }, (_, index) => (
					<div
						key={index}
						style={{
							backgroundColor: index <= visibleBars ? color : "darkgray",
							width: UNIT,
							height: UNIT * (index + 1),
							border: "1px solid black",
							boxSizing: "border-box",
						}}
					/>
				))}
			</HorizontalSpacer>
		</div>
	);
};
