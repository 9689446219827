import Box2D from "box2dweb";
import { InstanceHandler, InstanceId, WithInstanceProps } from "./InstanceHandler";
import { Vector2 } from "../math/Vector";
import { CreateBody } from "./Box2DHelpers";
import { Render } from "./RenderHelpers";

export type ProjectileDescriptor = {
	vel: Vector2;
	idPlayer: InstanceId;
	bounces?: number;
};

export type ProjectileInstance = WithInstanceProps<{
	type: "projectile";
	lifespan: number;
	bounces: number;
	direction: Vector2;
	angle: number;
	idPlayer: InstanceId;
	id: InstanceId;
}>;

export class ProjectileHandler extends InstanceHandler<ProjectileDescriptor, ProjectileInstance> {
	private bodyRadius: number;
	private maxBounces: number;

	constructor(world: Box2D.Dynamics.b2World, bodyRadius: number, maxBounces: number) {
		super(
			world,
			(props, id) => {
				const dir = Vector2.dir(props.vel);
				return {
					type: "projectile",
					lifespan: 0,
					bounces: props.bounces ?? 0,
					direction: dir,
					angle: Vector2.angle(dir),
					idPlayer: props.idPlayer,
					id,
					body: CreateBody.Projectile(world, props.pos.x, props.pos.y, bodyRadius),
					dead: false,
				};
			},
			(proj) => ({
				pos: proj.body.GetPosition(),
				vel: proj.body.GetLinearVelocity(),
				idPlayer: proj.idPlayer,
				bounces: proj.bounces,
			}),
		);
		this.bodyRadius = bodyRadius;
		this.maxBounces = maxBounces;
	}

	public render(context: CanvasRenderingContext2D, pixelsPerUnit: number) {
		for (const [, projectile] of this.iterator()) {
			Render.Projectile(context, projectile, pixelsPerUnit, this.maxBounces, this.bodyRadius * pixelsPerUnit);
		}
	}
}
