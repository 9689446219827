import Box2D from "box2dweb";
import { ObjectDescriptor } from "./ObjectHandler";

const COLLISION_CATEGORIES = {
	WALL: 0b0001,
	PLAYER: 0b0010,
	PLAYER_SENSOR: 0b0100,
	PROJECTILE: 0b1000,
};

const COLLISION_FILTERS = {
	wall: {
		group: 1,
		category: COLLISION_CATEGORIES.WALL,
		mask: COLLISION_CATEGORIES.PLAYER | COLLISION_CATEGORIES.PROJECTILE,
	},
	player: {
		group: -1,
		category: COLLISION_CATEGORIES.PLAYER,
		mask: COLLISION_CATEGORIES.WALL | COLLISION_CATEGORIES.PROJECTILE,
	},
	player_sensor: {
		group: -2,
		category: COLLISION_CATEGORIES.PLAYER_SENSOR,
		mask: COLLISION_CATEGORIES.PROJECTILE,
	},
	projectile: {
		group: -3,
		category: COLLISION_CATEGORIES.PROJECTILE,
		mask: COLLISION_CATEGORIES.WALL | COLLISION_CATEGORIES.PLAYER_SENSOR,
	},
};

function createStaticObject(
	world: Box2D.Dynamics.b2World,
	x: number,
	y: number,
	shape: Box2D.Collision.Shapes.b2Shape,
): Box2D.Dynamics.b2Body {
	const bodyDef = new Box2D.Dynamics.b2BodyDef();
	bodyDef.type = Box2D.Dynamics.b2Body.b2_staticBody;
	bodyDef.position.Set(x, y);

	const fixtureDef = new Box2D.Dynamics.b2FixtureDef();
	fixtureDef.shape = shape;
	fixtureDef.filter.groupIndex = COLLISION_FILTERS.wall.group;
	fixtureDef.filter.categoryBits = COLLISION_FILTERS.wall.category;
	fixtureDef.filter.maskBits = COLLISION_FILTERS.wall.mask;

	const body = world.CreateBody(bodyDef);
	body.CreateFixture(fixtureDef);
	return body;
}

export const CreateBody = {
	StaticObject(world: Box2D.Dynamics.b2World, desc: ObjectDescriptor): Box2D.Dynamics.b2Body {
		switch (desc.type) {
			case "rectangle": {
				const shape = new Box2D.Collision.Shapes.b2PolygonShape();
				shape.SetAsBox(desc.size.x / 2, desc.size.y / 2);
				return createStaticObject(world, desc.pos.x, desc.pos.y, shape);
			}
			case "circle": {
				const shape = new Box2D.Collision.Shapes.b2CircleShape(desc.radius);
				return createStaticObject(world, desc.pos.x, desc.pos.y, shape);
			}
			case "triangle": {
				const shape = new Box2D.Collision.Shapes.b2PolygonShape();
				shape.SetAsArray(
					[
						new Box2D.Common.Math.b2Vec2(desc.size.x / 2, desc.size.y / 2),
						new Box2D.Common.Math.b2Vec2(-desc.size.x / 2, desc.size.y / 2),
						new Box2D.Common.Math.b2Vec2(-desc.size.x / 2, -desc.size.y / 2),
					],
					3,
				);
				return createStaticObject(world, desc.pos.x, desc.pos.y, shape);
			}
		}
	},

	Player(world: Box2D.Dynamics.b2World, x: number, y: number, radius: number): Box2D.Dynamics.b2Body {
		const bodyDef = new Box2D.Dynamics.b2BodyDef();
		bodyDef.type = Box2D.Dynamics.b2Body.b2_dynamicBody;
		bodyDef.position.Set(x, y);

		const fixtureDef = new Box2D.Dynamics.b2FixtureDef();
		const shape = new Box2D.Collision.Shapes.b2CircleShape(radius);
		fixtureDef.shape = shape;
		fixtureDef.filter.groupIndex = COLLISION_FILTERS.player.group;
		fixtureDef.filter.categoryBits = COLLISION_FILTERS.player.category;
		fixtureDef.filter.maskBits = COLLISION_FILTERS.player.mask;

		const sensorFixture = new Box2D.Dynamics.b2FixtureDef();
		sensorFixture.shape = new Box2D.Collision.Shapes.b2CircleShape(radius);
		sensorFixture.isSensor = true;
		sensorFixture.filter.groupIndex = COLLISION_FILTERS.player_sensor.group;
		sensorFixture.filter.categoryBits = COLLISION_FILTERS.player_sensor.category;
		sensorFixture.filter.maskBits = COLLISION_FILTERS.player_sensor.mask;

		const body = world.CreateBody(bodyDef);
		body.CreateFixture(fixtureDef);
		body.CreateFixture(sensorFixture);
		body.SetFixedRotation(true);
		return body;
	},

	Projectile(world: Box2D.Dynamics.b2World, x: number, y: number, radius: number): Box2D.Dynamics.b2Body {
		const bodyDef = new Box2D.Dynamics.b2BodyDef();
		bodyDef.type = Box2D.Dynamics.b2Body.b2_dynamicBody;
		bodyDef.position.Set(x, y);

		const fixtureDef = new Box2D.Dynamics.b2FixtureDef();
		const shape = new Box2D.Collision.Shapes.b2CircleShape(radius);
		fixtureDef.shape = shape;
		fixtureDef.friction = 0;
		fixtureDef.restitution = 1;
		fixtureDef.filter.groupIndex = COLLISION_FILTERS.projectile.group;
		fixtureDef.filter.categoryBits = COLLISION_FILTERS.projectile.category;
		fixtureDef.filter.maskBits = COLLISION_FILTERS.projectile.mask;

		const body = world.CreateBody(bodyDef);
		body.CreateFixture(fixtureDef);
		body.SetFixedRotation(true);
		return body;
	},
};
