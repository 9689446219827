import { RicochetGame } from "./RicochetGame";
import { InputController } from "./InputController";
import { Vector2 } from "../math/Vector";
import { RicochetContactListener } from "./RicochetContactListener";
import { InstanceId } from "./InstanceHandler";
import { ClientDefinition, PlayerInput, ServerDefinition } from "./RicochetGameTypes";
import { StateManager } from "../StateManager";
import { PlayerHandler } from "./PlayerHandler";
import { SocketClient } from "../socket/SocketClient";
import Box2D from "box2dweb";

export type CanvasCallback = {
	connect: (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => void;
	disconnect: () => void;
};

export class RicochetGameClient extends RicochetGame implements CanvasCallback {
	private inputController: InputController;
	private animationFrameId: number | undefined;
	private canvas?: HTMLCanvasElement;
	private context?: CanvasRenderingContext2D;
	private idPlayer: InstanceId;
	private inputState: StateManager<PlayerInput>;
	//	private renderer: Box2D.Dynamics.b2DebugDraw;

	constructor(payload: ClientDefinition["begin"]["payload"]) {
		super(payload.settings);
		this.world.SetContactListener(
			new RicochetContactListener({ "projectile-wall": this.projectileHitWall.bind(this) }),
		);
		this.playerHandler.decodeAll(payload.desc.players);
		this.projectileHandler.decodeAll(payload.desc.projectiles);
		this.inputController = new InputController();
		this.idPlayer = payload.id;
		this.inputState = new StateManager({ move: Vector2.new(0, 0), mouse: Vector2.new(0, 0) });
		// this.renderer = new Box2D.Dynamics.b2DebugDraw();
		// this.world.SetDebugDraw(this.rendered);
	}

	public destroy() {
		super.destroy();
		this.disconnect();
	}

	public connect(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
		this.inputController.connect(canvas, document);
		context.lineWidth = 1;
		context.strokeStyle = "black";
		this.canvas = canvas;
		this.context = context;
	}

	public disconnect() {
		this.inputController.disconnect();
		this.canvas = undefined;
		this.context = undefined;
	}

	public getPlayerInput(): PlayerInput {
		return {
			move: this.inputController.getInputDirection(false),
			mouse: this.inputController.getMousePos(),
		};
	}

	public receivePlayerInputs(payload: ClientDefinition["inputs"]["payload"]) {
		for (const [id, input] of payload) {
			if (id === this.idPlayer) continue;
			this.applyPlayerInput(id, input);
		}
	}

	public receivePlayerPositions(payload: ClientDefinition["pos"]["payload"]) {
		for (const [id, pos] of payload) {
			const player = this.playerHandler.get(id);
			if (!player) continue;
			const currentPos = player.body.GetPosition();
			const delta = Vector2.sub(pos, currentPos);
			if (Vector2.length(delta) > 1) {
				player.body.SetPosition(new Box2D.Common.Math.b2Vec2(pos.x, pos.y));
			} else {
				player.body.SetPosition(new Box2D.Common.Math.b2Vec2(currentPos.x + delta.x / 4, currentPos.y + delta.y / 4));
			}
		}
	}

	public receivePlayerHit(payload: ClientDefinition["hit"]["payload"]) {
		this.projectileHitPlayer(payload.idProjectile, payload.idPlayer);
	}

	public receiveProjectiles(payload: ClientDefinition["projectile"]["payload"]) {
		for (const [id, props] of payload) {
			this.projectileHandler.create(props, id);
		}
	}

	public step() {
		super.step();
	}

	public render() {
		if (this.context && this.canvas) {
			//this.world.DrawDebugData();
			this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.objectHandler.render(this.context, this.settings.pixelsPerUnit);
			this.playerHandler.render(this.context, this.settings.pixelsPerUnit, this.idPlayer);
			this.projectileHandler.render(this.context, this.settings.pixelsPerUnit);
		}
	}

	public start(socket: SocketClient<ClientDefinition, ServerDefinition>): boolean {
		if (this.animationFrameId) return false;
		const startTime = this.time();
		const run = (time: number) => {
			const desiredFrame = this.desiredFrame(time / 1000);
			if (desiredFrame > this.frame) {
				const input = this.getPlayerInput();
				const player = this.playerHandler.get(this.idPlayer);
				if (player && PlayerHandler.isAlive(player)) {
					const delta = this.inputState.update(input);
					if (delta) {
						this.applyPlayerInput(this.idPlayer, delta);
						this.inputState.clearDelta();
						socket.send("input", { frame: this.frame, input: delta });
					}
					if (this.inputController.isMouseDown() && PlayerHandler.canShoot(player)) {
						PlayerHandler.doShoot(player);
						const pos = player.body.GetPosition();
						const angle = Vector2.angle(Vector2.sub(this.toWorldSpace(input.mouse), pos));
						socket.send("shoot", { frame: this.frame, pos, angle });
					}
				}
			}
			this.stepTo(startTime + time / 1000);
			this.render();
			window.requestAnimationFrame(run);
		};
		this.animationFrameId = window.requestAnimationFrame(run);
		return true;
	}

	public stop(): boolean {
		if (this.animationFrameId) {
			window.cancelAnimationFrame(this.animationFrameId);
			this.animationFrameId = undefined;
			return true;
		}
		return false;
	}
}
