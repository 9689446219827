import Box2D from "box2dweb";
import { Vector2 } from "../math/Vector";

const b2Vec2 = Box2D.Common.Math.b2Vec2;

export type InstanceProps = {
	body: Box2D.Dynamics.b2Body;
	dead: boolean;
};

export type WithInstanceProps<T> = T & InstanceProps;

export type BodyProps = {
	pos: Vector2;
	vel?: Vector2;
	angle?: number;
};

export type InstanceId = number;

export type Encoded<Descriptor> = [InstanceId, Required<Descriptor> & BodyProps][];

export class InstanceHandler<Descriptor, Instance extends InstanceProps> {
	private instances: Map<InstanceId, Instance>;
	private id: number;
	private world: Box2D.Dynamics.b2World;
	private factory: (props: Descriptor & BodyProps, id: InstanceId) => Instance;
	public readonly encode: (instance: Instance) => Required<Descriptor> & BodyProps;
	private toDelete: Set<Box2D.Dynamics.b2Body>;

	constructor(
		world: Box2D.Dynamics.b2World,
		factory: (props: Descriptor & BodyProps, id: InstanceId) => Instance,
		encode: (instance: Instance) => Required<Descriptor> & BodyProps,
	) {
		this.instances = new Map();
		this.id = 0;
		this.world = world;
		this.factory = factory;
		this.encode = encode;
		this.toDelete = new Set();
	}

	public uuid(): InstanceId {
		while (this.instances.has(this.id)) {
			this.id++;
		}
		return this.id;
	}

	public encodeAll(): Encoded<Descriptor> {
		return Array.from(this.instances.entries()).map(([id, instance]) => [id, this.encode(instance)]);
	}

	public decodeAll(all: Encoded<Descriptor>): void {
		this.deleteAll();
		for (const [id, props] of all) {
			this.create(props, id);
		}
	}

	public create(props: Descriptor & BodyProps, id?: InstanceId): [number, Instance] {
		if (id === undefined) id = this.uuid();
		const instance = this.factory(props, id);
		instance.body.SetUserData(instance);
		if (props.vel !== undefined) instance.body.SetLinearVelocity(new b2Vec2(props.vel.x, props.vel.y));
		if (props.angle) instance.body.SetAngle(props.angle);
		this.instances.set(id, instance);
		return [id, instance];
	}

	public createAll(all: (Descriptor & BodyProps)[]) {
		for (const props of all) {
			this.create(props);
		}
	}

	public update(id: InstanceId, { pos, vel, angle }: Partial<BodyProps>): Instance | undefined {
		const instance = this.instances.get(id);
		if (!instance) return undefined;
		if (pos !== undefined) instance.body.SetPosition(new b2Vec2(pos.x, pos.y));
		if (vel !== undefined) instance.body.SetLinearVelocity(new b2Vec2(vel.x, vel.y));
		if (angle !== undefined) instance.body.SetAngle(angle);
		return instance;
	}

	public get(id: InstanceId): Instance | undefined {
		return this.instances.get(id);
	}

	public delete(id: InstanceId): boolean {
		const instance = this.instances.get(id);
		if (!instance) return false;
		this.toDelete.add(instance.body);
		instance.dead = true;
		this.instances.delete(id);
		return true;
	}

	public deleteAll(): void {
		this.instances.forEach((instance) => {
			this.world.DestroyBody(instance.body);
		});
		this.instances.clear();
	}

	public iterator(): IterableIterator<[InstanceId, Instance]> {
		return this.instances.entries();
	}

	public clean() {
		for (const body of this.toDelete) {
			this.world.DestroyBody(body);
		}
		this.toDelete.clear();
	}

	public count(): number {
		return this.instances.size;
	}
}
