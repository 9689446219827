import React, { CSSProperties } from "react";
import styles from "./Shared.module.css";

type SpacerProps = {
	center?: boolean;
	centerAcross?: boolean;
	gap?: number;
	style?: CSSProperties;
	children?: React.ReactNode;
};

export const HorizontalSpacer: React.FC<SpacerProps> = ({ center, centerAcross, gap, style = {}, children }) => {
	return (
		<div
			className={styles["h-spacer"]}
			style={{
				gap,
				justifyContent: center ? "center" : undefined,
				alignItems: centerAcross ? "center" : undefined,
				...style,
			}}
		>
			{children}
		</div>
	);
};

export const VerticalSpacer: React.FC<SpacerProps> = ({ center, centerAcross, gap, style = {}, children }) => {
	return (
		<div
			className={styles["v-spacer"]}
			style={{
				gap,
				justifyContent: center ? "center" : undefined,
				alignItems: centerAcross ? "center" : undefined,
				...style,
			}}
		>
			{children}
		</div>
	);
};
