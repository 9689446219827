import React from "react";
import "../App.css";
import { Grid } from "./Board/Grid";
import { BattleshipDispatch, BattleshipGameState, Socket, useGame } from "../../Hooks/useBattleshipGame";
import { Overlay } from "../Shared/Overlay";
import { ShipSelect } from "./Attack/ShipSelect";
import {
	AttackInstance,
	Orientation,
	ShipPlacement,
	canPlaceShips,
	getPointsFromPattern,
	getPointsFromPlacement,
	serializePoint,
} from "../../Shared/battleship/BattleshipSharedTypes";
import { PlacingShips, PointMap } from "./Types";
import { AttackSelect } from "./Attack/AttackSelect";
import { useThrottle } from "../../Hooks/useThrottle";
import { HorizontalSpacer } from "../Shared/Spacer";
import { Point } from "../../Shared/math/Vector";

const MS_HOVER_UPDATE = Math.ceil(1000 / 30);

type BattleshipGameProps = {
	state: BattleshipGameState;
	dispatch: BattleshipDispatch;
	socket: Socket;
};

function updateShipPlacements(placingShips: PlacingShips, dispatch: BattleshipDispatch, confirmed: boolean) {
	const placements = placingShips.map((p) => p.placement).filter(Boolean) as ShipPlacement[];
	dispatch({ type: "set_ships", placements, confirmed });
}

const BattleshipPlacement: React.FC<BattleshipGameProps> = ({ state, dispatch, socket }) => {
	const shipMap = React.useMemo(() => {
		const map = new Map<string, ShipPlacement>();
		for (const ship of state.player.ships) {
			for (const { x, y } of getPointsFromPlacement(ship)) {
				map.set(serializePoint({ x, y }), ship);
			}
		}
		return map;
	}, [state.player.ships]);

	const placingShips = React.useMemo<PlacingShips>(
		() =>
			state.gameSettings.shipList.map((descriptor) => ({
				placement: undefined,
				descriptor,
			})),
		[state.gameSettings.shipList],
	);

	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [hoveredPlacement, setHoveredPlacement] = React.useState<ShipPlacement | undefined>();
	const [orientation, setOrientation] = React.useState<Orientation>(Orientation.Horizontal);

	const onSelectButton = React.useCallback(
		(index: number) => {
			if (placingShips[index]?.placement) {
				placingShips[index].placement = undefined;
				updateShipPlacements(placingShips, dispatch, false);
			}
			setSelectedIndex(index);
		},
		[dispatch, placingShips],
	);

	const selectedSet = React.useMemo<Set<string>>(() => {
		const set = new Set<string>();
		if (!hoveredPlacement) return set;
		for (const { x, y } of getPointsFromPlacement(hoveredPlacement)) {
			set.add(serializePoint({ x, y }));
		}
		return set;
	}, [hoveredPlacement]);

	const onHover = React.useCallback(
		function (x: number, y: number) {
			const placingShip = placingShips[selectedIndex];
			if (!placingShip || placingShip.placement) return;
			const placement = {
				descriptor: placingShip.descriptor,
				orientation,
				position: { x, y },
			};
			setHoveredPlacement(placement);
		},
		[selectedIndex, orientation, placingShips],
	);

	const onClick = React.useCallback(
		function (x: number, y: number) {
			onHover(x, y);
			if (!hoveredPlacement) return;
			const existingPlacements = placingShips.map((p) => p.placement).filter(Boolean) as ShipPlacement[];
			const newPlacements = [hoveredPlacement, ...existingPlacements];
			if (canPlaceShips(state.gameSettings.boardSize, newPlacements)) {
				placingShips[selectedIndex].placement = hoveredPlacement;
				updateShipPlacements(placingShips, dispatch, false);
				setHoveredPlacement(undefined);
				for (let i = 0; i < placingShips.length; i++) {
					const nextIndex = (selectedIndex + i) % placingShips.length;
					if (!placingShips[nextIndex].placement) {
						setSelectedIndex(nextIndex);
						return;
					}
				}
				setSelectedIndex(-1);
			}
		},
		[dispatch, onHover, hoveredPlacement, placingShips, state.gameSettings.boardSize, selectedIndex],
	);

	const rotate = React.useCallback(() => {
		const newOrientation = orientation === Orientation.Horizontal ? Orientation.Vertical : Orientation.Horizontal;
		setOrientation(newOrientation);
		if (hoveredPlacement) {
			setHoveredPlacement({
				...hoveredPlacement,
				orientation: newOrientation,
			});
		}
	}, [hoveredPlacement, orientation]);

	React.useEffect(() => {
		function handleKeyDown(event: KeyboardEvent) {
			if (event.key === "r") {
				rotate();
				event.preventDefault();
				return;
			}
		}
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [orientation, hoveredPlacement, rotate]);

	const onMouseLeaveGrid = React.useCallback(() => setHoveredPlacement(undefined), []);

	function canSubmit() {
		return placingShips.every((p) => !!p.placement);
	}

	function submit() {
		if (!canSubmit()) return;
		const ships = placingShips.map((p) => p.placement) as ShipPlacement[];
		if (!canPlaceShips(state.gameSettings.boardSize, ships)) {
			console.error("Invalid ship placement");
			placingShips.forEach((p) => (p.placement = undefined));
			return;
		}
		dispatch({ type: "set_ships", placements: ships, confirmed: true });
		socket.send("board", { placements: ships });
	}

	const opponentText =
		state.gameState === "WaitingForPlayers"
			? "Waiting for an opponent..."
			: !state.opponent.shipsConfirmed
				? "Placing ships..."
				: "Ready!";

	return (
		<HorizontalSpacer>
			<div>
				<Grid
					x={state.gameSettings.boardSize.x}
					y={state.gameSettings.boardSize.y}
					onHover={onHover}
					onClick={onClick}
					onMouseLeaveGrid={onMouseLeaveGrid}
					shipMap={shipMap}
					selectedSet={selectedSet}
					contentAbove={<h2>Your board</h2>}
					contentRight={<ShipSelect ships={placingShips} indexSelected={selectedIndex} onSelect={onSelectButton} />}
					contentBelow={
						<HorizontalSpacer center={true}>
							<button
								className={`App-button ${placingShips[selectedIndex] ? "" : "disabled"}`}
								style={{ minWidth: 200 }}
								onClick={rotate}
							>
								Rotate (R)
							</button>
							<button
								className={`App-button ${canSubmit() ? "" : "disabled"}`}
								style={{ minWidth: 200 }}
								onClick={submit}
							>
								Submit
							</button>
						</HorizontalSpacer>
					}
				/>
			</div>
			<div style={{ position: "relative" }}>
				{<Overlay text={opponentText} />}
				<Grid
					x={state.gameSettings.boardSize.x}
					y={state.gameSettings.boardSize.y}
					contentAbove={<h2>Opponent&apos;s board</h2>}
					tileColor="grey"
				/>
			</div>
		</HorizontalSpacer>
	);
};

function canUseAttack(point: Point, attack: AttackInstance, hitMap: PointMap, boardSize: Point): boolean {
	if (attack.limited && attack.count <= 0) return false;

	// At least one point in this attack is new
	for (const p of getPointsFromPattern(point, attack.option.pattern, boardSize.x, boardSize.y)) {
		if (!hitMap.has(serializePoint(p))) {
			console.log(hitMap, p);
			return true;
		}
	}

	return false;
}

const BattleshipPlay: React.FC<BattleshipGameProps> = ({ state, dispatch, socket }) => {
	const shipMap = React.useMemo(() => {
		const map = new Map<string, ShipPlacement>();
		for (const ship of state.player.ships) {
			for (const { x, y } of getPointsFromPlacement(ship)) {
				map.set(serializePoint({ x, y }), ship);
			}
		}
		return map;
	}, [state.player.ships]);

	const hoverAttack = React.useMemo(() => state.opponent.selectedTiles, [state.opponent.selectedTiles]);

	const [hoverPoint, setHoverPoint] = React.useState<Point | undefined>();
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const onSelectButton = React.useCallback((index: number) => {
		setSelectedIndex(index);
	}, []);

	const selectedSet = React.useMemo<Set<string>>(() => {
		const set = new Set<string>();
		if (!hoverPoint) return set;
		const attack = state.attackInstances[selectedIndex];
		if (!attack) return set;
		for (const { x, y } of attack.option.pattern) {
			set.add(serializePoint({ x: hoverPoint.x + x, y: hoverPoint.y + y }));
		}
		return set;
	}, [hoverPoint, selectedIndex, state.attackInstances]);

	const throttleFunction = React.useCallback(
		(payload: { index: number; point: Point }) => socket.send("hover_attack", payload),
		[socket],
	);

	const throttleSend = useThrottle(MS_HOVER_UPDATE, throttleFunction);

	const onHover = React.useCallback(
		function (x: number, y: number) {
			if (!state.isPlayerTurn) return;
			setHoverPoint({ x, y });
			throttleSend({
				index: selectedIndex,
				point: { x, y },
			});
		},
		[selectedIndex, state.isPlayerTurn, throttleSend],
	);

	const onMouseLeaveGrid = React.useCallback(() => setHoverPoint(undefined), []);

	const onClick = React.useCallback(
		function (x: number, y: number) {
			if (!state.isPlayerTurn) return;
			const attack = state.attackInstances[selectedIndex];
			if (!attack) return;
			if (!canUseAttack({ x, y }, attack, state.opponent.hitMap, state.gameSettings.boardSize)) return;
			dispatch({
				type: "use_attack",
				index: selectedIndex,
			});
			socket.send("attack", {
				index: selectedIndex,
				point: { x, y },
			});
			setHoverPoint(undefined);
			if (attack.limited && attack.count <= 1) {
				for (let i = 1; i < state.attackInstances.length; i++) {
					const nextIndex = (selectedIndex + i) % state.attackInstances.length;
					if (state.attackInstances[nextIndex].count > 0 || !state.attackInstances[nextIndex].limited) {
						setSelectedIndex(nextIndex);
						return;
					}
				}
			}
		},
		[
			state.isPlayerTurn,
			state.attackInstances,
			state.opponent.hitMap,
			state.gameSettings.boardSize,
			selectedIndex,
			dispatch,
			socket,
		],
	);

	if (state.gameState !== "Playing") {
		const opponentText =
			state.gameState === "WaitingForPlayers"
				? "Waiting for an opponent..."
				: !state.opponent.shipsConfirmed
					? "Placing ships..."
					: undefined;

		return (
			<HorizontalSpacer>
				<div>
					<Grid
						x={state.gameSettings.boardSize.x}
						y={state.gameSettings.boardSize.y}
						shipMap={shipMap}
						contentAbove={<h2>Your board</h2>}
						tileColor="grey"
					/>
				</div>
				<div style={{ position: "relative" }}>
					{opponentText && <Overlay text={opponentText} />}
					<Grid
						x={state.gameSettings.boardSize.x}
						y={state.gameSettings.boardSize.y}
						contentAbove={<h2>{`Opponent's board`}</h2>}
						tileColor="grey"
					/>
				</div>
			</HorizontalSpacer>
		);
	}

	return (
		<HorizontalSpacer>
			<div>
				<Grid
					x={state.gameSettings.boardSize.x}
					y={state.gameSettings.boardSize.y}
					shipMap={shipMap}
					hitMap={state.player.hitMap}
					contentAbove={<h2>{"Your board"}</h2>}
					tileColor="grey"
					selectedSet={state.isPlayerTurn ? undefined : hoverAttack}
					contentBelow={!state.isPlayerTurn ? <h2>{"Opponent's turn"}</h2> : undefined}
				/>
			</div>
			<div style={{ position: "relative" }}>
				<Grid
					x={state.gameSettings.boardSize.x}
					y={state.gameSettings.boardSize.y}
					hitMap={state.opponent.hitMap}
					onHover={onHover}
					onClick={onClick}
					onMouseLeaveGrid={onMouseLeaveGrid}
					selectedSet={selectedSet}
					tileColor={state.isPlayerTurn ? undefined : "grey"}
					contentAbove={<h2>{`Opponent's board`}</h2>}
					contentRight={
						<AttackSelect instances={state.attackInstances} indexSelected={selectedIndex} onSelect={onSelectButton} />
					}
					contentBelow={state.isPlayerTurn ? <h2>{"Your turn"}</h2> : undefined}
				/>
			</div>
		</HorizontalSpacer>
	);
};

const BattleshipGameOver: React.FC<BattleshipGameProps> = ({ state }) => {
	const shipMap = React.useMemo(() => {
		const map = new Map<string, ShipPlacement>();
		for (const ship of state.player.ships) {
			for (const { x, y } of getPointsFromPlacement(ship)) {
				map.set(serializePoint({ x, y }), ship);
			}
		}
		return map;
	}, [state.player.ships]);

	const enemyShipMap = React.useMemo(() => {
		const map = new Map<string, ShipPlacement>();
		for (const ship of state.opponent.ships) {
			for (const { x, y } of getPointsFromPlacement(ship)) {
				map.set(serializePoint({ x, y }), ship);
			}
		}
		return map;
	}, [state.opponent.ships]);

	return (
		<HorizontalSpacer>
			<div>
				<Grid
					x={state.gameSettings.boardSize.x}
					y={state.gameSettings.boardSize.y}
					shipMap={shipMap}
					hitMap={state.player.hitMap}
					contentAbove={<h2>{"Your board"}</h2>}
					contentBelow={
						<h2 style={{ color: state.didPlayerWin ? "green" : "red" }}>
							{state.didPlayerWin ? "Victory!" : "Defeat"}
						</h2>
					}
				/>
			</div>
			<div style={{ position: "relative" }}>
				<Grid
					x={state.gameSettings.boardSize.x}
					y={state.gameSettings.boardSize.y}
					shipMap={enemyShipMap}
					hitMap={state.opponent.hitMap}
					contentAbove={<h2>{`Opponent's board`}</h2>}
				/>
			</div>
		</HorizontalSpacer>
	);
};

type BattleshipProps = {
	ws: WebSocket;
};

export const Battleship: React.FC<BattleshipProps> = ({ ws }) => {
	const { state, dispatch, socket } = useGame(ws);

	switch (state.gameState) {
		case "WaitingForSettings":
			return <Overlay text="Loading..." />;
		case "WaitingForPlayers":
		case "ShipPlacement":
			if (state.player.shipsConfirmed) {
				return <BattleshipPlay {...{ state, dispatch, socket }} />;
			} else {
				return <BattleshipPlacement {...{ state, dispatch, socket }} />;
			}
		case "Playing":
			return <BattleshipPlay {...{ state, dispatch, socket }} />;
		case "Ended":
			return <BattleshipGameOver {...{ state, dispatch, socket }} />;
	}
};
