export function withDefault<T, K>(obj: T, defaultObj: Required<K>) {
	return Object.assign({}, defaultObj, obj);
}

export function withoutDefault<T extends Record<string, unknown>>(obj: T, defaultObj: Required<T>): Partial<T> {
	for (const key in defaultObj) {
		if (Object.hasOwn(obj, key) && obj[key] === defaultObj[key]) {
			delete obj[key];
		}
	}
	return obj;
}

type ValueOf<T> = T[keyof T];

export const mapObject = <OldObject extends object, NewValue>(
	obj: OldObject,
	mappingFn: (value: ValueOf<OldObject>) => NewValue,
): Record<keyof OldObject, NewValue> => {
	const newObj = {} as Record<keyof OldObject, NewValue>;
	for (const i in obj) {
		if (Object.hasOwn(obj, i)) {
			newObj[i] = mappingFn(obj[i]);
		}
	}
	return newObj;
};
