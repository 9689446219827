import Box2D from "box2dweb";
import { MutableVector2, Vector2 } from "../math/Vector";

export type ContactInfo = {
	normal: Vector2;
};

export type ContactHandlers = Record<
	string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(instance1: any, instance2: any, info: ContactInfo) => void
>;

const MANIFOLD = new Box2D.Collision.b2WorldManifold();

export class RicochetContactListener extends Box2D.Dynamics.b2ContactListener {
	private handlers: ContactHandlers;

	constructor(handlers: ContactHandlers) {
		super();
		this.handlers = handlers;
	}

	BeginContact(contact: Box2D.Dynamics.Contacts.b2Contact): void {
		let bodyA = contact.GetFixtureA().GetBody();
		let dataA = bodyA.GetUserData();

		let bodyB = contact.GetFixtureB().GetBody();
		let dataB = bodyB.GetUserData();

		let handler = this.handlers[`${dataA?.type}-${dataB?.type}`];
		if (!handler) {
			[dataA, dataB] = [dataB, dataA];
			[bodyA, bodyB] = [bodyB, bodyA];
			handler = this.handlers[`${dataA?.type}-${dataB?.type}`];
		}
		if (handler) {
			contact.GetWorldManifold(MANIFOLD);
			let normal: MutableVector2 = MANIFOLD.m_normal;
			if (normal.x === 0 && normal.y === 0) {
				normal = MutableVector2.unit(MutableVector2.sub(bodyA.GetPosition().Copy(), bodyB.GetPosition()));
			}
			handler(dataA, dataB, { normal });
			return;
		}
	}
}
