import React from "react";

export function useThrottle<T>(debounceMs: number, push: (payload: T) => void) {
	const timerRef = React.useRef<NodeJS.Timeout>();
	const payloadRef = React.useRef<T>();

	const enqueue = React.useCallback(
		(payload: T) => {
			payloadRef.current = payload;
			if (!timerRef.current) {
				push(payload);
				timerRef.current = setTimeout(() => {
					if (payloadRef.current !== undefined && payloadRef.current !== payload) {
						push(payloadRef.current);
					}
					timerRef.current = undefined;
				}, debounceMs);
			}
		},
		[debounceMs, push],
	);

	return enqueue;
}
