export const PAYLOAD_KEY = "p" as const;

export type CommunicationDefinition = Record<string, { payload: unknown; return?: unknown }>;

export type MessageFromDefinition<T extends CommunicationDefinition, K extends keyof T> = {
	type: K;
	[PAYLOAD_KEY]: T[K]["payload"];
};

export type ReturnMessageFromDefinition<T extends CommunicationDefinition, K extends keyof T> = {
	type: K;
	[PAYLOAD_KEY]: T[K]["return"];
};

export type InvokeKeys<T> = {
	[K in keyof T]: T[K] extends { return: unknown } ? (K extends string ? K : never) : never;
}[keyof T];

export type SendKeys<T> = {
	[K in keyof T]: T[K] extends { return: unknown } ? never : K extends string ? K : never;
}[keyof T];

export type GenerateMessages<T extends CommunicationDefinition> = {
	[K in keyof T]: MessageFromDefinition<T, K>;
}[keyof T];

export type GenerateSendMessages<T extends CommunicationDefinition> = {
	[K in keyof T]: MessageFromDefinition<T, K>;
}[SendKeys<T>];

export type GenerateReducerMessages<T extends CommunicationDefinition> = {
	[K in keyof T]: {
		type: K;
		payload: T[K]["payload"];
	};
}[SendKeys<T>];

type ValueOf<T> = T[keyof T];

type MustInclude<T, U extends T[]> = [T] extends [ValueOf<U>] ? U : never;

export function generateReducerHandlers<T extends CommunicationDefinition, K extends SendKeys<T>>(
	names: MustInclude<SendKeys<T>, K[]>,
	reducer: (payload: GenerateReducerMessages<T>) => void,
): GenerateSendHandlers<T> {
	const handlers: Record<string, (payload: GenerateSendMessages<T>) => void> = {};
	for (const name of names) {
		handlers[name] = (payload: T[K]["payload"]) =>
			reducer({
				type: name,
				payload,
			});
	}
	return handlers as GenerateSendHandlers<T>;
}

export type GenerateSendHandlers<T extends CommunicationDefinition> = {
	[K in keyof T as T[K] extends { return: unknown } ? never : K]: (payload: T[K]["payload"]) => void;
};

export type GenerateInvokeHandlers<T extends CommunicationDefinition> = {
	[K in keyof T as T[K] extends { return: unknown } ? K : never]: (payload: T[K]["payload"]) => T[K]["return"];
};

export type GenerateHandlers<T extends CommunicationDefinition> = {
	[K in keyof T]: (payload: T[K]["payload"]) => T[K] extends { return: unknown } ? T[K]["return"] : void;
};

export type GenerateServerHandlers<T extends CommunicationDefinition, Client> = {
	[K in keyof T]: (
		payload: T[K]["payload"],
		client: Client,
	) => T[K] extends { return: unknown } ? T[K]["return"] : void;
};

export type Validators<T extends CommunicationDefinition> = {
	[K in keyof T]?: (payload: unknown) => T[K]["payload"];
};

export type Payload<T extends CommunicationDefinition, K extends keyof T> = T[K]["payload"];

export type Payloads<T extends CommunicationDefinition> = T[keyof T]["payload"];

export interface Sendable {
	send: (message: string) => void;
}

export type SocketSettings = {
	timeoutMs: number;
};
