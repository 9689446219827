import React from "react";
import "../App.css";
import "./Lobby.module.css";
import { VerticalSpacer } from "../Shared/Spacer";
import { OptionSelect } from "./OptionSelect";

type OptionRowsProps = {
	keys: readonly string[];
	rows: Record<
		string,
		{
			label: string;
			options: string[];
		}
	>;
	selections: number[];
	canEdit: boolean;
	cycle?: boolean;
	onSelect: (rowIndex: number, optionIndex: number) => void;
};

const OptionRowsFC: React.FC<OptionRowsProps> = ({ keys, rows, selections, onSelect, cycle = false, canEdit }) => {
	//const [selections, setSelections] = useImmer(rows.map((row) => row.index ?? 0));

	const selectionUpdate = React.useMemo(
		() => (row: number) => (option: number) => {
			onSelect(row, option);
		},
		[onSelect],
	);

	console.log("Ops re-render");

	const selectionUpdates = React.useMemo(() => keys.map((key, i) => selectionUpdate(i)), [keys, selectionUpdate]);

	return (
		<VerticalSpacer gap={8}>
			{keys
				.map((key, i) => {
					const row = rows[key];
					if (!row) return null;
					return (
						<OptionSelect
							key={`option-row-${key}`}
							options={row.options}
							label={row.label}
							index={selections[i]}
							cycle={cycle}
							onSelect={selectionUpdates[i]}
							canEdit={canEdit}
						></OptionSelect>
					);
				})
				.filter((row) => row !== null)}
		</VerticalSpacer>
	);
};

export const OptionRows = React.memo(OptionRowsFC);
