import { Vector2 } from "../math/Vector";

const KEYBINDS = {
	LEFT: "a",
	RIGHT: "d",
	UP: "w",
	DOWN: "s",
};

export class InputController {
	private heldKeys: Set<string>;
	private mouseDown: boolean;
	private mouseDownWaiting: boolean;
	private mousePos: { x: number; y: number };
	private element?: HTMLElement;
	private eventElement?: Document;
	private onKeyDownBound: (event: KeyboardEvent) => void;
	private onKeyUpBound: (event: KeyboardEvent) => void;
	private onMouseDownBound: (event: MouseEvent) => void;
	private onMouseUpBound: (event: MouseEvent) => void;
	private onMouseMoveBound: (event: MouseEvent) => void;

	constructor() {
		this.heldKeys = new Set();
		this.mouseDown = false;
		this.mouseDownWaiting = false;
		this.mousePos = { x: 0, y: 0 };
		this.onKeyDownBound = this.onKeyDown.bind(this);
		this.onKeyUpBound = this.onKeyUp.bind(this);
		this.onMouseDownBound = this.onMouseDown.bind(this);
		this.onMouseUpBound = this.onMouseUp.bind(this);
		this.onMouseMoveBound = this.onMouseMove.bind(this);
	}

	public isKeyDown(key: string): boolean {
		return this.heldKeys.has(key);
	}

	private onKeyDown(event: KeyboardEvent) {
		this.heldKeys.add(event.key.toLowerCase());
	}

	private onKeyUp(event: KeyboardEvent) {
		this.heldKeys.delete(event.key.toLowerCase());
	}

	public onMouseDown() {
		this.mouseDownWaiting = true;
		this.mouseDown = true;
	}

	public onMouseUp() {
		this.mouseDown = false;
	}

	public onMouseMove(event: MouseEvent) {
		if (!this.element) return;
		const rect = this.element.getBoundingClientRect();
		this.mousePos = { x: event.clientX - rect.left, y: event.clientY - rect.top };
	}

	public disconnect() {
		if (!this.eventElement) return;
		this.eventElement.removeEventListener("keydown", this.onKeyDownBound);
		this.eventElement.removeEventListener("keyup", this.onKeyUpBound);
		this.eventElement.removeEventListener("mousedown", this.onMouseDownBound);
		this.eventElement.removeEventListener("mouseup", this.onMouseUpBound);
		this.eventElement.removeEventListener("mousemove", this.onMouseMoveBound);
		this.eventElement = undefined;
		this.element = undefined;
		this.heldKeys.clear();
	}

	public connect(element: HTMLElement, eventElement: Document) {
		this.disconnect();
		this.element = element;
		this.eventElement = eventElement;
		this.eventElement.addEventListener("keydown", this.onKeyDownBound);
		this.eventElement.addEventListener("keyup", this.onKeyUpBound);
		this.eventElement.addEventListener("mousedown", this.onMouseDownBound);
		this.eventElement.addEventListener("mouseup", this.onMouseUpBound);
		this.eventElement.addEventListener("mousemove", this.onMouseMoveBound);
		// this.element.focus();
	}

	public getInputDirection(normalized: boolean = true): Vector2 {
		const x = (this.heldKeys.has(KEYBINDS.RIGHT) ? 1 : 0) - (this.heldKeys.has(KEYBINDS.LEFT) ? 1 : 0);
		const y = (this.heldKeys.has(KEYBINDS.DOWN) ? 1 : 0) - (this.heldKeys.has(KEYBINDS.UP) ? 1 : 0);
		if (x === 0 && y === 0) return Vector2.ZERO;
		return normalized ? Vector2.unit({ x, y }) : { x, y };
	}

	public getMousePos(pixelsPerUnit: number = 1): Vector2 {
		return Vector2.mul(this.mousePos, 1 / pixelsPerUnit);
	}

	public isMouseDown(): boolean {
		return this.mouseDown;
	}

	public didMouseDown(): boolean {
		const result = this.mouseDownWaiting;
		this.mouseDownWaiting = false;
		return result;
	}
}
