import React from "react";
import { Helmet } from "react-helmet";
import "../Components/App.css";
import { LobbyMenu } from "../Components/Lobby/LobbyMenu";
import { RicochetLobby } from "../Components/Ricochet/RicochetLobby";

export const RicochetIndex: React.FC = () => {
	const [socket, setSocket] = React.useState<WebSocket | undefined>();
	return (
		<>
			<Helmet></Helmet>
			{socket === undefined ? (
				<LobbyMenu title="Ricochet Tanks" setSocket={setSocket} />
			) : (
				<RicochetLobby ws={socket} />
			)}
		</>
	);
};

export default RicochetIndex;
