import Box2D from "box2dweb";
import { InstanceHandler, InstanceId, WithInstanceProps } from "./InstanceHandler";
import { CreateBody } from "./Box2DHelpers";
import { Vector2 } from "../math/Vector";
import { shortestSignedAngle } from "../math/Angle";
import { Render } from "./RenderHelpers";
import { PlayerInput } from "./RicochetGameTypes";

const ANGULAR_SPEED = Math.PI;

export type PlayerDescriptor = {
	desiredAngle?: number;
	angle?: number;
	moveAngle?: number;
	team?: number;
	hp?: number;
	iframes?: number;
	input?: PlayerInput;
};

/*
const PlayerDescriptorDefault: Required<PlayerDescriptor> = {
	desiredAngle: 0,
	angle: 0,
	moveAngle: 0,
	team: 0,
	hp: 1,
	iframes: 0,
} as const;*/

export type PlayerInstance = WithInstanceProps<{
	type: "player";
	desiredAngle: number;
	angle: number;
	moveAngle: number;
	team: number;
	hp: number;
	fireCooldown: number;
	iframes: number;
	input: PlayerInput;
	id: InstanceId;
}>;

export class PlayerHandler extends InstanceHandler<PlayerDescriptor, PlayerInstance> {
	private bodyRadius: number;

	public static canTakeDamage(player: PlayerInstance): boolean {
		return PlayerHandler.isAlive(player) && player.iframes <= 0 && !player.dead;
	}

	public static isAlive(player: PlayerInstance): boolean {
		return player.hp > 0;
	}

	public static dealDamage(player: PlayerInstance, damage: number) {
		player.hp -= damage;
		if (!PlayerHandler.isAlive(player)) {
			// Causes an error to set this during collision
			player.body.SetLinearVelocity(new Box2D.Common.Math.b2Vec2(0, 0));
			// player.body.SetActive(false);
		} else {
			player.iframes = 120;
		}
	}

	public static canShoot(player: PlayerInstance, leniency: number = 0): boolean {
		return PlayerHandler.isAlive(player) && player.fireCooldown <= leniency;
	}

	public static doShoot(player: PlayerInstance) {
		player.fireCooldown += 30;
	}

	private lives: number;

	constructor(world: Box2D.Dynamics.b2World, bodyRadius: number, lives: number) {
		super(
			world,
			(props, id) => ({
				type: "player",
				desiredAngle: props.desiredAngle ?? 0,
				angle: props.angle ?? 0,
				moveAngle: props.moveAngle ?? 0,
				team: props.team ?? 0,
				hp: props.hp ?? lives,
				fireCooldown: 0,
				iframes: props.iframes ?? 0,
				input: props.input ?? { move: Vector2.new(0, 0), mouse: Vector2.new(0, 0) },
				id,
				body: CreateBody.Player(world, props.pos.x, props.pos.y, bodyRadius),
				dead: false,
			}),
			(player) => ({
				desiredAngle: player.desiredAngle,
				angle: player.angle,
				moveAngle: player.moveAngle,
				team: player.team,
				hp: player.hp,
				iframes: player.iframes,
				input: player.input,
				pos: player.body.GetPosition(),
				vel: player.body.GetLinearVelocity(),
			}),
		);
		this.bodyRadius = bodyRadius;
		this.lives = lives;
	}

	public step(dt: number, playerSpeed: number, toWorldSpace: (pos: Vector2) => Vector2) {
		for (const [, player] of this.iterator()) {
			if (!PlayerHandler.isAlive(player)) continue;
			const pos = player.body.GetPosition();
			const dir = Vector2.dir(Vector2.sub(toWorldSpace(player.input.mouse), pos));
			const desAngle = Vector2.angle(dir);
			const move = Vector2.mul(Vector2.toMaxLength(player.input.move, 1), playerSpeed);
			player.body.SetLinearVelocity(new Box2D.Common.Math.b2Vec2(move.x, move.y));
			player.desiredAngle = desAngle;
			if (!Vector2.isZero(move)) {
				player.moveAngle = Vector2.angle(move);
			}

			if (player.fireCooldown > 0) {
				player.fireCooldown--;
			}
			if (player.iframes > 0) {
				player.iframes--;
			}

			const angle = player.angle;
			if (angle !== desAngle) {
				const delta = shortestSignedAngle(angle, desAngle);
				if (Math.abs(delta) <= ANGULAR_SPEED * dt) {
					player.angle = desAngle;
				} else {
					const newAngle = angle + (delta > 0 ? ANGULAR_SPEED : -ANGULAR_SPEED) * dt;
					player.angle = newAngle;
				}
			}
		}
	}

	public render(context: CanvasRenderingContext2D, pixelsPerUnit: number, highlightPlayer?: InstanceId) {
		for (const [id, player] of this.iterator()) {
			if (id === highlightPlayer) continue;
			Render.Player(context, player, pixelsPerUnit, highlightPlayer);
		}
		if (highlightPlayer !== undefined) {
			const player = this.get(highlightPlayer);
			if (player) {
				Render.Player(context, player, pixelsPerUnit, highlightPlayer);
				Render.Health(context, 14, 14, player.hp, this.lives);
			}
		}
	}
}
